const ENDPOINT = 'https://backend.biosecuritytrack.com/api/v1/granjas'

export default function listGranjas (jwt, activo = 1) {
  return fetch(`${ENDPOINT}/list`, {
    method: 'GET',
    headers: { Authorization: "Bearer " + jwt }
  }).then(res => {
    const data = res.json()
    if(res.status === 401){
      return res.status;
    }
    return data
  }).then(res => {
    if(res === 401){
      return res;
    }else{
      if (activo === 0 ) {
        return res.data
      } else {
        return res.data.filter(granja => granja.activo === '1');
      }
    }
  })
}