import React, { useEffect, useState } from "react";
import { Link} from "react-router-dom";
import lupa from "../../img/lupaw.svg";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import Avatar from "@mui/material/Avatar";
import Badge from '@mui/material/Badge';
import { Stack } from "@mui/system";
import useUser from "../../hooks/useUser"
import perfil from "../../img/perfil.jpg"


export const Cabecera = () => {
  
  const { jwt, isLogged, user, avatar, fetchAvatar, notificacionesPendientes } = useUser();
 
  const [imagenAvatar, setImagenAvatar] = useState("");
  const [nombreUSuario, setNombreUsuario ] = useState("");

  const rellenaDatos = () => {

    const userName = user.nombre;
    if (userName) {
      const palabras = userName.split(" ");
      setNombreUsuario(palabras[0])
    }
    
    if (user?.imagen){
      const imagen = user.imagen; 
      const idAvatar = imagen[0].id;
      const token = jwt;
      fetchAvatar({token, idAvatar});
    }
   
    if (avatar) { 
        setImagenAvatar(avatar);
    } else {
      setImagenAvatar(perfil)
    }
  }


  useEffect(() => {
    if (isLogged) {
      rellenaDatos();
    }
    // eslint-disable-next-line 
  }, [isLogged, avatar, user])

 
  return (
    <nav className="navbar navbar-light bg-body-tertiary justify-content-between bg-azulbio">
      { isLogged && <Link className="backHeader" onClick={() => window.history.back()} to="#">
        <ArrowBackIosNewOutlinedIcon fontSize="large" />
      </Link>}
      {isLogged && (
        <>
          <Stack direction="row" spacing={2} className="fotoPerfil">
            <Link to="/miperfil">

              <Badge
                className="badgeBio"
                overlap="circular"
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                badgeContent={notificacionesPendientes}>
                <Avatar
                    src={imagenAvatar}
                    id="imageId"
                    alt="MiPerfil"
                    sx={{ width: 60, height: 60 }}
                  />
              </Badge>

              {<span>{nombreUSuario}</span>}
            </Link>
          </Stack>
        </>
      )}
      {isLogged && (<Link to="/dashboard">
        <img
          src={lupa}
          alt="Logo"
          width="60"
          height="60"
          className="d-inline-block align-text-top"
        />
      </Link>)}
      {!isLogged && (<div className="cabeceraPublica"><Link to="/login"><img
          src={lupa}
          alt="Logo"
          width="60"
          height="60"
          className="d-inline-block align-text-top"
        /></Link></div>)}
    </nav>
  );
};
