import React from 'react'
import FullCalendar from '@fullcalendar/react' 
import dayGridPlugin from '@fullcalendar/daygrid' 
import listTareas from '../../services/tareas';
import { useState } from 'react';
import useUser from '../../hooks/useUser';
import { useEffect } from 'react';
import interactionPlugin from "@fullcalendar/interaction"
import { NavLink } from 'react-router-dom';
import esLocale from '@fullcalendar/core/locales/es'
import listUser from '../../services/users';
import listGranjas from '../../services/granjas';
import { Store } from '@mui/icons-material';
import { useRef } from 'react';


export const Tareas = () => {

  const {jwt, isLogged, user, notification, logout } = useUser(); 

  const [ tareas, setTareas ] = useState('');
  const [events, setEvents ] = useState('');

  const [usuarios, setUsuarios] = useState('');
  const [granjas, setGranjas] = useState('');

  const [veterinarioTarea, setVeterinarioTarea] = useState('');
  const [granjaTarea, setGranjaTarea] = useState('');

  const veterinarioTareaOnchangeHandler = (evento) => {
    setVeterinarioTarea(evento.target.value);
  }

  const granjaTareaOnchangeHandler = (evento) => {
    setGranjaTarea(evento.target.value);
  }

  const userRole = user.role;

  const getUsuarios = () => {
    listUser(jwt).then(usuarios => {
        if(usuarios===401){
          Store.addNotification({
            ...notification,
            id: " ",
            title: "Ha habido un error",
            message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
            type: "danger",
          });
            logout();         
        }else{
            setUsuarios(usuarios);
        }
    })
  };

const getGranjas = () => {
  listGranjas(jwt).then(granjas => {
    if(granjas === 401){
      Store.addNotification({
        ...notification,
        id: " ",
        title: "Ha habido un error",
        message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
        type: "danger",
      });
      logout();
    }else {
      setGranjas(granjas);
    }
  })
}


useEffect(() => {
  if (userRole === '1') {
    getUsuarios();
  }
  getGranjas();
}, []) 

useEffect(() => {
  actualizaTareas();
}, [jwt, isLogged]);

const calendarRef = useRef(null);

const handleDayClick = (date) => {
  const calendarApi = calendarRef.current?.getApi();
  if (calendarApi) {
    calendarApi.changeView('dayGridDay', date);
  }
};

useEffect(() => {
  const cells = document.querySelectorAll('.fc-daygrid-day');
  cells.forEach((cell) => {
    cell.addEventListener('click', () => {
      const date = cell.getAttribute('data-date');
      if (date) {
        handleDayClick(new Date(date));
      }
    });
  });

  return () => {
    cells.forEach((cell) => {
      cell.removeEventListener('click', () => {
        const date = cell.getAttribute('data-date');
        if (date) {
          handleDayClick(new Date(date));
        }
      });
    });
  };
}, []);

const actualizaTareas = async (evento = {}) => {
  
  if (typeof evento.preventDefault === 'function') {
    evento.preventDefault();
  }

  const formElement = document.querySelector('.formTareas');
  const data = new FormData(formElement);

  listTareas({jwt, data}).then(tareas => {
    if(tareas===401){
      Store.addNotification({
        ...notification,
        id: " ",
        title: "Ha habido un error",
        message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
        type: "danger",
      });
        logout();         
    }else{
      setTareas(tareas);
      setEvents(tareas);
    }
})



}

    return (
    <>
    <div className='container'>

      <h5><span className='spanTitulo'>Listado de tareas: </span></h5>
      
    </div> 

    <nav className="navbar navbar-light bg-light justify-content-center">
          
            <button className="btn btn-primary btn-lg btn-block boton-azulbio btn-azul boton-addUser boton-notas" type="submit">
                <NavLink to="/tareas/crear" className="addUser" >Crear tarea</NavLink>
            </button>
        
  </nav>
  <br />
    <div className='containerCalendario'>

      <form className='formTareas'>

    { userRole === '1' ? (<div className="col-md-12">
            <div className="form-group">
                <label className="labelForm" htmlFor="id_usuario">Seleccione personal:</label>
                <select className="form-select form-select form-control" 
                        aria-label=".form-select-sm example"
                        id="usuario"
                        name="usuario"
                        value={veterinarioTarea}
                        onChange={veterinarioTareaOnchangeHandler}>
                  <option key="veterinario-0" value="">Seleccione</option>
                  {
                    usuarios && usuarios.map(usuario => {
                      return <option key={usuario.id} value={usuario.id}>{usuario.nombre}</option> 
                    })
                  }
                </select>
              </div></div>) : (<input type="hidden" value={user.id} id="usuario" name="usuario" />)} 

            <div className="col-md-12">
            <div className="form-group">
                <label className="labelForm" htmlFor="id_granja">Seleccione granja:</label>
                <select className="form-select form-select form-control" 
                        aria-label=".form-select-sm example"
                        id="granja"
                        name="granja"
                        value={granjaTarea}
                        onChange={granjaTareaOnchangeHandler}>
                  <option key="granja-0" value="">Seleccione</option>
                  {
                    granjas && granjas.map(granja => {
                      return <option key={granja.id} value={granja.id}>{granja.nombre}</option> 
                    })
                  }
                </select>
              </div></div>
              <br />

              <button
                  className="btn btn-primary btn-lg btn-block boton-azulbio btn-azul"
                  type="submit"
                  onClick={actualizaTareas}
                >
                  Consultar
                </button>
        <br />  <br /> 
        </form>
            
      <FullCalendar
        ref={calendarRef}
        plugins={[ dayGridPlugin, interactionPlugin ]}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,dayGridDay'
        }}
        locale={esLocale}
        navLinks="true"
        initialView="dayGridMonth"
        events={events}
      />
      <br />
     </div>

    </>  
    )
};