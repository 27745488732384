import {useCallback, useContext, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import Context from '../context/UserContext'
import loginService from '../services/login'
import avatarService from '../services/avatar'

export default function useUser () {
  const { jwt, setJWT, user, setUser , avatar, setAvatar, notificacionesPendientes, getNotificacionesPendientes } = useContext(Context)
  const [state, setState] = useState({ loading: false, error: false })
  

  const navigate = useNavigate();

  const notification = {
    insert: "top",
    container: "top-right",
    animationIn: ["animate_animated", "animate_fadeIn"],
    animationOut: ["animate_animated", "animate_fadeOut"],
    dismiss: {
      duration: 2000,
      onScreen: true
    }
  };

  
  const login = useCallback(({username, password}) => {
    setState({loading: true, error: false })
    loginService({username, password}) // aqui llama a loginservice
      .then(respuesta => {
        const token = respuesta.token;
        const userObj = JSON.stringify(respuesta.user);
        localStorage.setItem('jwt', token);
        localStorage.setItem('user', userObj);
        
        setState({loading: false, error: false });
        setJWT(respuesta.token);
        setUser(JSON.parse(userObj));
        navigate('/dashboard');
      })
      .catch(err => {
        localStorage.removeItem('jwt')
        setState({loading: false, error: true })
      })
  }, [setJWT, jwt, navigate])

  const cambiarError = useCallback(() => {
    setState({loading: false, error: false })
  },[] )

  const logout = useCallback(() => {
    localStorage.removeItem('user');
    localStorage.removeItem('jwt');
    localStorage.removeItem('avatar');
    setAvatar('')
    setJWT(null)
    navigate('/login');
  }, [setJWT])

  const fetchAvatar = useCallback(({ token, idAvatar}) => {
    avatarService({token, idAvatar}) 
      .then(avatar => {
        localStorage.setItem('avatar', avatar)
        setAvatar(avatar)
      })
      .catch(err => {
        setState({loading: false, error: true })
        console.error(err)
      })
    }, [setJWT, jwt, navigate])



  return {
    jwt,
    isLogged: Boolean(jwt),
    isLoginLoading: state.loading,
    hasLoginError: state.error,
    login,
    logout,
    cambiarError,
    user,
    setUser,
    avatar,
    setAvatar,
    fetchAvatar,
    notification,
    notificacionesPendientes,
    getNotificacionesPendientes

  }
} 