const ENDPOINT = 'https://backend.biosecuritytrack.com/api/v1/naves'

export default function listNaves (jwt, idGranja, activo = 1) {
  return fetch(`${ENDPOINT}/list/` + idGranja, {
    method: 'GET',
    headers: { Authorization: "Bearer " + jwt }
  }).then(res => {
    const data = res.json()
    if(res.status === 401){
      return res.status;
    }
    return data
  }).then(res => {
    if(res === 401){
      return res;
    }else{
      if (activo === 0 ) {
        return res.data
      } else {
        return res.data.filter(nave => nave.activo === '1');
      }
    }
  })
}