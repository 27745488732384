import React, { useState } from 'react';
import { Global } from '../../helpers/Global';
import { Navigate, useNavigate } from "react-router-dom";
import { Store } from 'react-notifications-component';
import useUser from '../../hooks/useUser';

function ContactForm() {

  const {jwt, isLogged,logout, notification} = useUser();
    
    const [mensaje, setMensage] = useState('');
    const [ saved, setSaved ] = useState('');

    const [open, setOpen] = useState("true");

    const handleSubmit = async (event) => {
        event.preventDefault();

        const contacto = new FormData();
        contacto.append("mensaje", mensaje);
       
        try {
            const response = await fetch(Global.url + 'contacto', { 
                method: 'POST',
                body: contacto,
                headers: { Authorization: "Bearer " + jwt },
            }) 
            .then((respuesta) => {
              if(respuesta.status === 401){
                Store.addNotification({
                  ...notification,
                  id: " ",
                  title: "Ha habido un error",
                  message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
                  type: "danger",
                });
                logout()
              }else if(respuesta.status === 500){
                Store.addNotification({
                  ...notification,
                  id: " ",
                  title: "Ha habido un error",
                  message: "Ha ocurrido un error",
                  type: "danger"
                })
              }
      
              return respuesta.json()
            })
            .then( 
                (final_response) => {
                    setSaved(final_response.message)
                    if (final_response.status === 201 ) {
                      Store.addNotification({
                        ...notification,
                        id: " ",
                        title: "Contacto enviado",
                        message: final_response.message,
                        type: "success",
                      })
                      setTimeout(() => {
                        navigate("/dashboard");
                      }, 2000);    
                    }
                    
                }
            );
            setMensage('');
        } catch (error) {
            //console.error(error);
        }
    };

    const navigate = useNavigate(); 

    return (
        <div className='container'>
          {
            !isLogged && <Navigate to="/login" />
          }
          <h5><span className='spanTitulo'>Haznos llegar tu consulta y nos pondremos en contacto cuanto antes</span></h5>
            
        <form  onSubmit={handleSubmit} id="form-contacto">

        <div className="form-group">  
            <label className='labelForm' htmlFor='contacto'>
                Mensaje:</label>
                <textarea className="textareaContacto" rows="10" cols="30" id="contacto" name="contacto" value={mensaje} onChange={event => setMensage(event.target.value)} /> 
            
            <br />
            </div>
            <button
                className="btn btn-primary btn-lg btn-block boton-azulbio btn-azul boton-perfil"
                type="submit"
              >            
          Enviar
        </button>
       
        </form>
        </div>
    );
}

export default ContactForm;