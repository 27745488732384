const ENDPOINT = 'https://backend.biosecuritytrack.com/api/v1/formularios'

export default function listForms (jwt) {

  return fetch(`${ENDPOINT}/list`, {
    method: 'GET',
    headers: { Authorization: "Bearer " + jwt }
  }).then(res => {
    const data = res.json()
    if(res.status === 401){
      return res.status;
    }
    return data
  }).then(res => {
    if(res === 401){
      return res;
    }else{
      return res.data
    }
  })
}

/** este es el que tengo que añadir el offset y el limit aparte de la paginación.
 *   return fetch(`${ENDPOINT}/list?limit=` + limit + "&offset=" + page*limit, {
 *   return fetch(`${ENDPOINT}/list?limit=` + limit + "&offset=" + page*limit, {

 * &limit=${limit}&offset=${

    page * limit

  }
 */