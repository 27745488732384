import React, { useEffect, useState } from 'react'
import { Navigate, NavLink } from 'react-router-dom'
import veterinarios from "../../img/3-veterinarios.svg";
import granjas from "../../img/4-granjas.svg";
import empresas from "../../img/2-Empresas.svg";
import productos from "../../img/5-productos.svg";
import useUser from "../../hooks/useUser"
import tareas from "../../img/Tareas_v2.svg"
import formularios from "../../img/6-formularios.svg"
import informes from "../../img/Informes.svg"
import estadisticas from "../../img/Evolucion.svg"

export const Dashboard = () => {

  const { jwt, isLogged, isLoginLoading, user, logout} = useUser();

  const [userRole, setUserRole ] = useState('');
 
  useEffect(() => {
    setUserRole(user.role)
  }, [isLogged, jwt])
  
  return (
    <>
   
    { !jwt ? <Navigate to="/login" /> : '' }
    
    <h5 className='pageTitle'>INICIO</h5>
     <div className='container'>
        <div className='menu'>

        {userRole === "1" ? (<NavLink to="/empresa">
          
          <div className='tarjeta-menu'>
            <div className='imageMenu'>
              <img src={empresas} alt="logo" />
              </div>
              <div className='texto'>
                <div className='titulo'>
                  <h5>Mi empresa</h5>
                  <div className='descripcion'>
                    <p>Ver los datos de tu empresa</p>
                  </div>
                </div>
              </div>
          </div>
          </NavLink>) : (
            ''
          )}

          {userRole === "1" ? (<NavLink to="/usuarios">
          
          <div className='tarjeta-menu'>
            <div className='imageMenu'>
              <img src={veterinarios} alt="logo" />
              </div>
              <div className='texto'>
                <div className='titulo'>
                  <h5>Gestión de personal</h5>
                  <div className='descripcion'>
                    <p>Creación y edición de perfiles</p>
                  </div>
                </div>
              </div>
          </div>
          </NavLink>) : (
            ''
          )}
          
          

          < NavLink to="/granjas">
          <div className='tarjeta-menu'>
            <div className='imageMenu'>
              <img src={granjas} alt="logo" />
            </div>
              <div className='texto'>
                <div className='titulo'>
                  <h5>Granjas</h5>
                  <div className='descripcion'>
                    <p>Gestión de granjas</p>
                  </div>
                </div>
              </div>
          </div>
          </NavLink>

          < NavLink to="/formularios">
          <div className='tarjeta-menu'>
            <div className='imageMenu'>
              <img src={formularios} alt="logo" />
            </div>
              <div className='texto'>
                <div className='titulo'>
                  <h5>Biosecurity Check</h5>
                  <div className='descripcion'>
                    <p>Análisis de Bioseguridad</p>
                  </div>
                </div>
              </div>
          </div>
          </NavLink>

          < NavLink to="/evolucion">
          <div className='tarjeta-menu'>
              <div className='imageMenu'>
              <img src={estadisticas} alt="logo" />
              </div>
              <div className='texto'>
                <div className='titulo'>
                  <h5>Evolución</h5>
                  <div className='descripcion'>
                    <p>Evolución de Bioseguridad.</p>
                  </div>
                </div>
              </div>
          </div> 
          </NavLink>

          < NavLink to="/tareas">
          <div className='tarjeta-menu'>
            <div className='imageMenu'>
              <img src={tareas} alt="logo" />
            </div>
              <div className='texto'>
                <div className='titulo'>
                  <h5>Tareas</h5>
                  <div className='descripcion'>
                    <p>Planificación de tareas</p>
                  </div>
                </div>
              </div>
          </div>
          </NavLink>

          < NavLink to="/categorias-informes">
          <div className='tarjeta-menu'>
              <div className='imageMenu'>
              <img src={informes} alt="logo" />
              </div>
              <div className='texto'>
                <div className='titulo'>
                  <h5>Informes</h5>
                  <div className='descripcion'>
                    <p>Gestión de documentación</p>
                  </div>
                </div>
              </div>
          </div> 
          </NavLink>

          < NavLink to="/categorias-productos">
          <div className='tarjeta-menu'>
              <div className='imageMenu'>
                <img src={productos} alt="logo" />
              </div>
              <div className='texto'>
                <div className='titulo'>
                  <h5>Productos</h5>
                  <div className='descripcion'>
                    <p>Información técnica de productos</p>
                  </div>
                </div>
              </div>
          </div> 
          </NavLink>

        </div>
    </div>  
    </>
   
  )
}
