import React, { useEffect, useState } from "react";
import { Store } from "react-notifications-component";
import { Navigate, NavLink } from "react-router-dom";
import useUser from "../../hooks/useUser"
import listCategoriasInformes from "../../services/categoriasInformes";
import { ListInformes } from "../modulos/informes/List";


export const Informes = () => {
    const { jwt, user, notification, logout } = useUser();
    const [searchField, setSearchField] = useState("");
    const [categorias, setCategorias] = useState("");

    const userRole = user.role;
    

    const handleChange = (e) => {setSearchField(e.target.value); };

    const getCategoriasInfomes = () => {
        listCategoriasInformes(jwt).then(categorias => {
            if(categorias===401){
                Store.addNotification({
                    ...notification,
                    id: " ",
                    title: "Ha habido un error",
                    message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
                    type: "danger",
                  });
                logout();         
            }else{
                setCategorias(categorias);
                console.log(categorias);
            }
        })
    };

    useEffect(() => {
        getCategoriasInfomes();
    }, []);

    return (
    <div className="container-title">
     
      { !jwt ? <Navigate to="/login" /> : '' }

      <h5><span className="spanTitulo">Listado de categorias: </span></h5>

      <nav className="navbar navbar-light bg-light justify-content-around">
        <div className="form-group form-actions">
                {/* userRole === '1' ? (<button className="btn btn-primary btn-lg btn-block boton-azulbio btn-azul  boton-addUser" type="submit">
                    <NavLink to="/informes/crear" className="addInforme" >Añadir nuevo</NavLink>
                </button>) : ('')*/}
                <button className="btn btn-primary btn-lg btn-block boton-azulbio btn-azul  boton-addUser" type="submit">
                    <NavLink to="/informes/crear" className="addInforme" >Añadir nuevo</NavLink>
                 </button>


            <form className="form-inline buscador">
            <input
                className="form-control mr-sm-2"
                type="search"
                onChange = {handleChange}
                placeholder="Buscar"
                aria-label="Buscar"/>
            </form>

            <hr />
        </div>
      </nav>
        
     <ListInformes busqueda={searchField} listado={categorias} />
    </div>
    );
};