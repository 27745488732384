import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import useUser from "../../../hooks/useUser";
import listProductos from "../../../services/productos";

import { Store } from "react-notifications-component";
import { Imagen } from "../imagenes";

export const EditProductos = () => {
  const { jwt, notification, logout } = useUser();

  const datosURL = useParams();
  const idCategoria = datosURL.id;

  const [productos, setProductos] = useState("");
  const [categoria, setCategoria] = useState("");  
  
  const getProductos = () => {
    listProductos(jwt, idCategoria).then((data) => {
      if (data === 401) {
        Store.addNotification({
          ...notification,
          id: " ",
          title: "Ha habido un error",
          message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
          type: "danger",
        });
        logout();
      } else {
        setProductos(data.productos);
        setCategoria(data.categoria.nombre);
      }
    });
  };

  useEffect(() => {
    getProductos();
  }, []);

  return (
    <div className="container-title">

      {categoria && (
        <h5>
          <span className="spanCategoriaTitulo">Categoria seleccionada: </span>
          <span className="spanCategoria">{categoria}</span>
        </h5>
      )}

      {productos && (
        <h5>
          <span className="spanTitulo">Listado de productos: </span>
        </h5>
      )}
      <div className="container-producto">
        {productos &&
          productos.map((producto, index) => {
            return (
              <div className="listadoProductos" key={producto.id}>
              <Link to={"/producto/"+ producto.id}>
                <div className="tarjetaProducto">
                  <Imagen imagenes={producto.imagen} numImagenes="1" accion="listadoProducto" />
                  <h5 className="tituloProducto">{producto.nombre}</h5>
                </div>
              </Link>
            </div>
            );
          })}
      </div>
    </div>
  );
};
