import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import useUser from "../../../hooks/useUser";
import { Store } from "react-notifications-component";
import { Global } from "../../../helpers/Global";
import { useEffect } from "react";
import Select from "react-select";
import { LoadingSpinner } from "../../../helpers/LoadingSpinner";

import listGranjas from "../../../services/granjas";


export const FormInformes = ({ accion, userId }) => {
  const { jwt, isLogged, notification, logout } = useUser();

  const [nombreInforme, setNombreInforme] = useState("");
  const [descripcionInforme, setDescripcionInforme] = useState("");
  const [ficheroInforme, setFicheroInforme] = useState("");

  const [categorias, setCategorias] = useState("");
  const [granjas, setGranjas] = useState("");

  const [ isLoading, setIsLoading ] = useState(false);

  const nombreInformeOnChangeHandler = (evento) => {
    setNombreInforme(evento.target.value);
  };

  const descripcionInformeOnChangeHandler = (evento) => {
    setDescripcionInforme(evento.target.value);
  };

  const ficheroInformeOnChangeHandler = (evento) => {
    setFicheroInforme(evento.target.value);
  };

  const getGranjas = () => {
    listGranjas(jwt).then(granjas => {
      if (granjas === 401) {
        Store.addNotification({
          ...notification,
          id: " ",
          title: "Ha habido un error",
          message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
          type: "danger",
        });
        logout();
      } else {
        setGranjas(granjas);
      }
    })
  }

  const navigate = useNavigate();

  const getCategoriasInformes = () => {
    fetch(Global.url + "select-categorias-informes", {
      method: "GET",
      headers: { Authorization: "Bearer " + jwt },
    })
      .then((respuesta) => {
        if (respuesta.status === 401) {
          Store.addNotification({
            ...notification,
            id: " ",
            title: "Ha habido un error",
            message:
              "Error de autenticación. Por favor vuelva a iniciar sesión.",
            type: "danger",
          });
          logout();
        } else if (respuesta.status === 400) {
          Store.addNotification({
            ...notification,
            id: " ",
            title: "Ha habido un error",
            message: "Ha ocurrido un error",
            type: "danger",
          });
        }
        return respuesta.json();
      })
      .then(
        (resultado_final) => {
          setCategorias(resultado_final.data);
          //console.log(categorias);
        },
        (error) => {
          //console.log(error)
        }
      );
  };


  /*const setOptionsMultiSelect = () => {
   if (categorias) {
    categorias.map((categoria) => {
      setOptions(
        ...options,
       categoria.nombre,
       categoria.id,
      )
    })
   }

   if (granjas) {
    granjas.map((granja) => {
      setOptions(
        ...options,
       granja.nombre,
       granja.id,
      )
    })
   }
  }*/

  useEffect(() => {
    getCategoriasInformes();
    getGranjas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged, jwt]);

  const submitInformes = async (evento) => {
    evento.preventDefault();
    setIsLoading(true);

    const formElement = document.querySelector(".informesForm");
    const data = new FormData(formElement);

    let url = Global.url + "informes/create";

    await fetch(url, {
      method: "POST",
      body: data,
      headers: { Authorization: "Bearer " + jwt },
    })
      .then((respuesta) => {
        const data = respuesta.json();
        if (respuesta.status === 401) {
          Store.addNotification({
            ...notification,
            id: " ",
            title: "Ha habido un error",
            message:
              "Error de autenticación. Por favor vuelva a iniciar sesión.",
            type: "danger",
          });
          logout();
        } else if (respuesta.status === 400) {
          data.then((value) => {
            Store.addNotification({
              ...notification,
              id: " ",
              title: "Ha habido un error",
              message: value.errors[0].message,
              type: "danger",
            });
            setIsLoading(false);
          });
        } else if (respuesta.status === 500) {
          Store.addNotification({
            ...notification,
            id: " ",
            title: "Ha habido un error",
            message: "Ha ocurrido un error",
            type: "danger",
          });
        }
        setIsLoading(false);
        return data;
      })
      .then((datos) => {
        if (datos.status === 201) {
            Store.addNotification({
              ...notification,
              id: " ",
              title: datos.message,
              message: datos.message,
              type: "success",
            });
            setIsLoading(false);
            navigate("/categorias-informes");
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  let opciones = [];

  categorias && categorias.map((categoria) => {
    opciones = [
      ...opciones,
      { label: categoria.nombre, value: categoria.id },
    ];
  })

  let opcionesGranjas = [];

  granjas && granjas.map((granja) => {
    opcionesGranjas = [
      ...opcionesGranjas,
      { label: granja.nombre, value: granja.id },
    ];
  })
 
 
  return (
    <div>
      { isLoading === true ? <LoadingSpinner /> : '' }
      {!isLogged ? <Navigate to="/login" /> : ""}

      <div className={isLoading ? "containerLoading" : "container"}>
        <br />
        <div className="contenedor-formulario">
          <form className="row g-3 informesForm" onSubmit={submitInformes}>
            <div className="col-md-12">
              <div className="form-group">
                <label className="labelForm" htmlFor="nombre">
                  Nombre:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="nombre"
                  name="nombre"
                  placeholder="Introduzca nombre de informe."
                  value={nombreInforme}
                  onChange={nombreInformeOnChangeHandler}
                />
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label className="labelForm" htmlFor="descripcion">
                  Descripcion:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="descripcion"
                  name="descripcion"
                  placeholder="Introduzca descripción de informe."
                  value={descripcionInforme}
                  onChange={descripcionInformeOnChangeHandler}
                />
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label className="labelForm" htmlFor="formFile">
                  Seleccione fichero:
                </label>
                <input
                  type="file"
                  className="form-control"
                  id="formFile"
                  name="formFile"
                  value={ficheroInforme}
                  onChange={ficheroInformeOnChangeHandler}
                />
              </div>
            </div>
            
            <div className="col-md-12">
              <div className="form-group">
                <label className="labelForm" htmlFor="categorias">
                  Seleccione categoría(s) de informe:
                </label>
                <Select
                   defaultValue={[categorias[0]]}
                   isMulti
                   name="categorias[]"
                   options={opciones}
                   className="basic-multi-select"
                   classNamePrefix="select"
                   placeholder="Seleccione una categoría o varias"
               />                 
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label className="labelForm" htmlFor="granjas">
                  Seleccione granja(s) para el informe:
                </label>
                <Select
                   defaultValue={[granjas[0]]}
                   isMulti
                   name="granjas[]"
                   options={opcionesGranjas}
                   className="basic-multi-select"
                   classNamePrefix="select"
                   placeholder="Seleccione una granja o varias"
                   
               />                 
              </div>
            </div>

            <button
              type="submit"
              className="btn btn-primary btn-lg btn-block boton-azulbio btn-azul"
            >Crear</button>
          </form>
        </div>
      </div>
    </div>
  );
};
