import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Global } from "../../../helpers/Global";
import Switch from "@mui/material/Switch";
import { Store } from "react-notifications-component";
import useUser from "../../../hooks/useUser";
import listFormsTypes from "../../../services/tiposFormularios";
import listGranjas from "../../../services/granjas";
import listNaves from "../../../services/naves";
import { FormControl, FormControlLabel, Typography } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { common } from "@mui/material/colors";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { LoadingSpinner } from "../../../helpers/LoadingSpinner";

export const FormForms = ({ accion, userId }) => {
  const { jwt, isLogged, notification, user, logout } = useUser();

  const [update, setUpdate] = useState(accion);

  const [tiposFormularios, setTiposFormularios] = useState("");
  const [granjas, setGranjas] = useState("");
  const [naves, setNaves] = useState("");
  const [preguntas, setPreguntas] = useState("");

  const [idFormulario, setIdFormulario] = useState("");
  const [idTipoFormulario, setIdTipoFormulario] = useState("");
  const [granjaFormulario, setGranjaFormulario] = useState("");
  const [naveFormulario, setNaveFormulario] = useState("");
  const [fechaFormulario, setFechaFormulario] = useState("");
  const [confirmaFormulario, setConfirmaFormulario] = useState(false);

  const [nombreGranja, setNombreGranja] = useState("");
  const [nombreFormulario, setNombreFormulario] = useState("");
  const [nombreNave, setNombreNave] = useState("");
  const [puntuacion, setPuntuacion] = useState("");
  const [puntuacionTotal, setPuntuacionTotal] = useState("");
  const [pdfFormulario, setPDFFormulario] = useState("");

  const [isLoading, setIsLoading] = useState(false);



  const navigate = useNavigate();

  const datosURL = useParams();
  const idForm = datosURL.id;

  const userRole = user.role;

  const idFormularioOnChangeHandler = (evento) => {
    setIdFormulario(evento.target.value);
  };

  const idTipoFormularioOnChangeHandler = (evento) => {
    setIdTipoFormulario(evento.target.value);
    getTiposFormularios(evento.target.value);
  };

  const granjaFormularioOnChangeHandler = (evento) => {
    setGranjaFormulario(evento.target.value);
    getNaves(evento.target.value);
  };

  const naveFormularioOnChangeHandler = (evento) => {
    setNaveFormulario(evento.target.value);
  };

  const fechaFormularioOnChangeHandler = (evento) => {
    setFechaFormulario(evento.target.value);
  };

  const [checked, setChecked] = useState("");

  const confirmaFormularioOnChangeHandler = (evento) => {
    setChecked(evento.target.checked);
    setConfirmaFormulario(evento.target.value === "2" ? true : false);
  };

  const submitFormulario = async (evento) => {
    evento.preventDefault();
    setIsLoading(true);

    const formElement = document.querySelector(".createForms");
    const data = new FormData(formElement);

    const dataentries = data.entries();

    //for (const entry of dataentries) console.log(entry);

    let url = "";

    if (accion === "update") {
      url = Global.url + "formularios/" + accion + "/" + idForm;
    } else {
      url = Global.url + "formularios/create";
    }

    await fetch(url, {
      method: "POST",
      body: data,
      headers: { Authorization: "Bearer " + jwt },
    })
      .then((respuesta) => {
        const data = respuesta.json();
        if (respuesta.status === 401) {
          Store.addNotification({
            ...notification,
            id: " ",
            title: "Ha habido un error",
            message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
            type: "danger",
          });
          logout();
        } else if (respuesta.status === 400) {
          data.then((value) => {
            Store.addNotification({
              ...notification,
              id: " ",
              title: "Ha habido un error",
              message: value.errors[0].message,
              type: "danger",
            });
          });
          setIsLoading(false);
          navigate('/formularios')
        } else if (respuesta.status === 500) {
          Store.addNotification({
            ...notification,
            id: " ",
            title: "Ha habido un error",
            message: "Ha ocurrido un error",
            type: "danger",
          });
          setIsLoading(false);
        }
        return data;
      })
      .then((datos) => {
        if (datos.status === 201) {
          Store.addNotification({
            ...notification,
            id: " ",
            title: datos.message,
            message: datos.message,
            type: "success",
          });
          setIsLoading(false);
          if (accion !== "update") {
            navigate("/formulario/" + datos.data.id);
          } else if (accion === "update") {
            navigate("/formularios");
          }
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const getFormData = () => {
    fetch(Global.url + "formulario/" + idForm, {
      method: "GET",
      headers: { Authorization: "Bearer " + jwt },
    })
      .then((respuesta) => {
        if (respuesta.status === 401) {
          Store.addNotification({
            ...notification,
            id: " ",
            title: "Ha habido un error",
            message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
            type: "danger",
          });
          logout();
        } else if (respuesta.status === 500) {
          Store.addNotification({
            ...notification,
            id: " ",
            title: "Ha habido un error",
            message: "Ha ocurrido un error",
            type: "danger",
          });
        } else if (respuesta.status === 400) {
          Store.addNotification({
            ...notification,
            id: " ",
            title: "Ha habido un error",
            message: "Ha ocurrido un error",
            type: "danger",
          });
          navigate('/formularios')
        }

        return respuesta.json();
      })
      .then(
        (resultado_final) => {
          setIdFormulario(resultado_final.data.id);
          setIdTipoFormulario(resultado_final.data.id_tipo_formulario);
          setGranjaFormulario(resultado_final.data.id_granja);
          setNaveFormulario(resultado_final.data.nave);
          setFechaFormulario(resultado_final.data.fecha_formato);
          setPreguntas(resultado_final.data.preguntas);
          setConfirmaFormulario(resultado_final.data.id_estado);
          setNombreGranja(resultado_final.data.granja);
          setNombreFormulario(resultado_final.data.nombre_formulario);
          setPuntuacion(resultado_final.data.puntuacion);
          setPuntuacionTotal(resultado_final.data.puntuacion_total)
          if (resultado_final.data.id_tipo_formulario === "2") {
            setNombreNave(resultado_final.data.nave);
          }

          if (resultado_final.data.id_estado === '2') {
            setPDFFormulario(resultado_final.data.pdf)
          }


          const respuestasObj = {};
          resultado_final.data.preguntas.forEach(
            (pregunta, i) =>
            (respuestasObj["respuestas[" + pregunta.id_pregunta + "]"] =
              pregunta.respuesta === "0" ? false : true)
          );
          setCheckedRespuesta(respuestasObj);
        },
        (error) => {
          // console.log(error);
        }
      );
  };

  useEffect(() => {
    if (accion === "update") {
      getFormData();
    }
  }, [accion]);

  const getTiposFormularios = (idTipoFormulario) => {
    listFormsTypes(jwt, idTipoFormulario).then((tiposFormularios) => {
      if (tiposFormularios === 401) {
        Store.addNotification({
          ...notification,
          id: " ",
          title: "Ha habido un error",
          message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
          type: "danger",
        });
        logout();
      } else {
        setTiposFormularios(tiposFormularios);
      }
    });
  };

  const getGranjas = () => {
    listGranjas(jwt).then((granjas) => {
      if (granjas === 401) {
        Store.addNotification({
          ...notification,
          id: " ",
          title: "Ha habido un error",
          message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
          type: "danger",
        });
        logout();
      } else {
        setGranjas(granjas);
      }
    });
  };

  const getNaves = (idGranja) => {
    listNaves(jwt, idGranja).then((naves) => {
      if (naves === 401) {
        Store.addNotification({
          ...notification,
          id: " ",
          title: "Ha habido un error",
          message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
          type: "danger",
        });
        logout();
      } else {
        setNaves(naves);
      }
    });
  };

  useEffect(() => {
    getGranjas();
  }, []);

  const [respuestas, setRespuestas] = useState({});

  const [checkedRespuesta, setCheckedRespuesta] = useState({});

  const handleChange = (event) => {
    setCheckedRespuesta({
      ...checkedRespuesta,
      [event.target.name]: event.target.checked,
    });
  };

  const verPdfFormulario = ({ e, idForm }) => {
    e.preventDefault();
    const ENDPOINT = "https://backend.biosecuritytrack.com/api/v1";

    return fetch(`${ENDPOINT}/formulario/view-pdf/` + idForm, {
      method: "GET",
      headers: { Authorization: "Bearer " + jwt },
    })
      .then((res) => {
        const data = res.blob();
        if (res.status === 401) {
          Store.addNotification({
            ...notification,
            id: " ",
            title: "Ha habido un error",
            message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
            type: "danger",
          });
          logout();
        }
        return data;
      })
      .then((res) => {
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(res);
        link.download = idForm;
        link.click();
      });
  };
  return (
    <div>
      {isLoading === true ? <LoadingSpinner /> : ''}
      {!isLogged ? <Navigate to="/login" /> : ""}

      <div className={isLoading ? "containerLoading" : "container"}>
        <h5 className="formTitle">
          {update === "update" ? (
            <span className="spanTitulo">Nuevo formulario:</span>
          ) : (
            <span className="spanTitulo">Crear formulario:</span>
          )}
        </h5>
        <br />
        <div className="contenedor-formulario">
          <form className="row g-2 createForms" onSubmit={submitFormulario}>
            <div className="col-md-12">
              <div className="form-group">
                <label className={`labelForm ${update === "update" ? 'labelFormularios' : ''}`} htmlFor="id_tipo_formulario">
                  Tipo de formulario:
                </label>
                {update !== "update" ? (
                  <select
                    className="form-select form-select form-control"
                    aria-label=".form-select-sm example"
                    id="id_tipo_formulario"
                    name="id_tipo_formulario"
                    value={idTipoFormulario}
                    onChange={idTipoFormularioOnChangeHandler}
                  >
                    <option key="0">Tipo de formulario: </option>
                    <option key="1" value="1">
                      Bioseguridad Externa
                    </option>
                    <option key="2" value="2">
                      Bioseguridad Interna
                    </option>
                  </select>
                ) : (
                  <span>
                    {" "}
                    {idTipoFormulario === "1"
                      ? "Bioseguridad Externa"
                      : "Bioseguridad Interna"}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label className={`labelForm ${update === "update" ? 'labelFormularios' : ''}`} htmlFor="id_formulario">
                  Formulario:
                </label>

                {update !== "update" ? (
                  <select
                    className="form-select form-select form-control"
                    aria-label=".form-select-sm example"
                    id="id_formulario"
                    name="id_formulario"
                    value={idFormulario}
                    onChange={idFormularioOnChangeHandler}
                  >
                    <option key="0">Tipo de formulario</option>
                    {tiposFormularios &&
                      tiposFormularios.map((tipoFormulario) => {
                        return (
                          <option
                            key={tipoFormulario.id}
                            value={tipoFormulario.id}
                          >
                            {tipoFormulario.nombre}
                          </option>
                        );
                      })}
                  </select>
                ) : (
                  <span> {nombreFormulario}</span>
                )}
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label className={`labelForm ${update === "update" ? 'labelFormularios' : ''}`} htmlFor="id_granja">
                  Granja:
                </label>
                {update !== "update" ? (
                  <select
                    className="form-select form-select form-control"
                    aria-label=".form-select-sm example"
                    id="id_granja"
                    name="id_granja"
                    value={granjaFormulario}
                    onChange={granjaFormularioOnChangeHandler}
                  >
                    <option key="0">Seleccione granja</option>
                    {granjas &&
                      granjas.map((granja) => {
                        return (
                          <option key={granja.id} value={granja.id}>
                            {granja.nombre}
                          </option>
                        );
                      })}
                  </select>
                ) : (
                  <span> {nombreGranja}</span>
                )}
              </div>
            </div>

            {idTipoFormulario === "2" ? (
              <div className="col-md-12">
                <div className="form-group">
                  <label className={`labelForm ${confirmaFormulario === "2" ? 'labelFormularios' : ''}`} htmlFor="id_nave">
                    Nave:
                  </label>
                  {update !== "update" ? (
                    <select
                      className="form-select form-select form-control"
                      aria-label=".form-select-sm example"
                      id="id_nave"
                      name="id_nave"
                      value={naveFormulario}
                      onChange={naveFormularioOnChangeHandler}
                    >
                      <option key="0">Seleccionar nave</option>
                      {naves &&
                        naves.map((nave) => {
                          return (
                            <option key={nave.id} value={nave.id}>
                              {nave.nombre}
                            </option>
                          );
                        })}
                    </select>
                  ) : (
                    <span> {naveFormulario}</span>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="col-md-12">
              <div className="form-group">
                <label className={`labelForm ${update === "update" ? 'labelFormularios' : ''}`} htmlFor="fecha">
                  Fecha:
                </label>
                {update !== "update" ? (
                  <input
                    type="date"
                    className="form-control"
                    id="fecha"
                    name="fecha"
                    value={fechaFormulario}
                    onChange={fechaFormularioOnChangeHandler}
                  />
                ) : (
                  <span> {fechaFormulario}</span>
                )}
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                {(update === "update" && confirmaFormulario === "2") ? (<Stack sx={{ width: '100%' }} spacing={2}>
                  <Alert variant="filled" severity="error">Formulario confirmado, no se puede editar.</Alert>
                </Stack>) : ('')}
              </div>
            </div>
            {
              confirmaFormulario === '2' ? (
                <div className="col-md-12">
                  {(update === "update" && confirmaFormulario === "2") ? (<div className="puntuacion"><span className="spanPuntuacion"> Puntuación obtenida: {puntuacion} / {puntuacionTotal}  </span></div>) : ('')}
                  <br /><button className="btn btn-primary btn-lg btn-block boton-azulbio btn-azul boton-formularios"
                    onClick={(e) =>
                      verPdfFormulario({ e, idForm })
                    }
                  >
                    <span className="botonPdf">Descargar resultados </span>
                    <PictureAsPdfIcon
                      sx={{ fontSize: 15, color: common.white }}
                    />
                  </button><br />
                </div>
              ) : ('')
            }
            {preguntas &&
              preguntas.map((pregunta, index) => {
                return (
                  <div className="contenedorPregunta" key={pregunta.id}>
                    <FormControl component="fieldset" variant="standard">
                      <div className="enunciadoPregunta">{pregunta.nombre}</div><div className="actionsForms">
                        <div className="switchRespuesta"><Typography>NO &nbsp;</Typography>
                          <FormControlLabel
                            control={
                              <Switch
                                key={index}
                                checked={
                                  checkedRespuesta[
                                  "respuestas[" + pregunta.id_pregunta + "]"
                                  ]
                                }
                                onChange={handleChange}
                                name={"respuestas[" + pregunta.id_pregunta + "]"}
                                value={pregunta.puntuacion}
                              />
                            }
                          /> <Typography>SI</Typography></div>{userRole === '1' ? (<span>Puntuación: {pregunta.puntuacion}</span>) : ('')}</div>
                    </FormControl>
                  </div>
                );
              })}

            <div className="col-md-12">
              <div className="form-group">
                {(update === "update" && confirmaFormulario !== "2") ? (<><span className="form-pass spanFormulario">
                  Confirmar borrador
                </span>
                  <Switch
                    value={confirmaFormulario}
                    checked={checked}
                    onChange={confirmaFormularioOnChangeHandler}
                    inputProps={{ "aria-label": "controlled" }}
                    className="form-check-input"
                    id="confirmado"
                    name="confirmado"
                  /> </>) : ('')}
              </div>
            </div>

            {(update === "update" && confirmaFormulario !== "2") ? (
              <div className="col-12">
                <button
                  type="submit"
                  className="btn btn-primary btn-lg btn-block boton-azulbio btn-azul"
                >Guardar
                </button>
              </div>) : ('')}

            {(update !== "update" && confirmaFormulario !== "2") ? (<div className="col-12">
              <button
                type="submit"
                className="btn btn-primary btn-lg btn-block boton-azulbio btn-azul"
              >Crear</button>
            </div>
            ) : ('')}
          </form>
        </div>
      </div>
    </div>
  );
};
