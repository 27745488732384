const ENDPOINT = 'https://backend.biosecuritytrack.com/api/v1'

export default function listProducto (jwt, idProducto) {
    return fetch(`${ENDPOINT}/producto/` + idProducto, {
      method: 'GET',
      headers: { Authorization: "Bearer " + jwt }
    }).then(res => {
      const data = res.json()
      if(res.status === 401 || res.status === 400 ){
        return res.status;
      }
      return data
    }).then(res => {
      if(res === 401 || res === 400){
        return res;
      }else{
        return res.data
      }
    })
  }