import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useSplash } from '../../context/SplashContext'
import useUser from '../../hooks/useUser'
import LoginNuevo from './LoginNuevo'

const sleep = (ms = 1000 ) => new Promise((resolve) => setTimeout(resolve, ms));

export const Login = () => {

  const {isLogged } = useUser();

 const { hideSplash, isShown } = useSplash();

 useEffect(() => {
  (async () => {
    await sleep(2000);
    hideSplash();
  }) ();
 }, []);

  
  return (
    <>
    {isShown ? null : (<><h2 className='pageTitle'>Login</h2>
        <LoginNuevo /></>)}
    {
      isLogged && <Navigate to="/dashboard" />
    }
        
    </>
  )
}