import React, {useState, useRef, useEffect} from 'react'

const Context = React.createContext({})

export function UserContextProvider ({children}) {
  const [jwt, setJWT] = useState(
    () => localStorage.getItem('jwt')
  )

  const [ user, setUser ] = useState(
    () => JSON.parse(localStorage.getItem('user'))
  )

  const [ avatar, setAvatar ] = useState(
    () => localStorage.getItem('avatar')
  )

  const [notificacionesPendientes, setNotificacionesPendientes ] = useState(0);

  const getNotificacionesPendientes = () => {
    
    const ENDPOINT = 'https://backend.biosecuritytrack.com/api/v1/notificaciones_pendientes'

    fetch(ENDPOINT, {
      method: 'GET',
      headers: { Authorization: "Bearer " + jwt }
    }).then(res => {
      const data = res.json()
      if(res.status === 401){
        return res.status;
      }
      return data
    }).then(res => {
      if(res === 401){
        return res;
      }else{
        if(res.data.total > 0){
            setNotificacionesPendientes(res.data.total);  
        }else{
          if(notificacionesPendientes > 0){
            setNotificacionesPendientes(0);  
          }  
        }
      }
    })
}

const ref = useRef(null)
  useEffect(() => {
    ref.current = setInterval(getNotificacionesPendientes, 5 * 60 * 1000);

    return () => {
      if(ref.current){
        clearInterval(ref.current)
      }
    }
  }, [])

useEffect(() => {
  if(jwt !== null){
    getNotificacionesPendientes();
  } 
}, [jwt])

  return <Context.Provider value={{
    jwt,
    setJWT,
    user,
    setUser,
    avatar,
    setAvatar,
    notificacionesPendientes,
    getNotificacionesPendientes
  }}>
    {children}
  </Context.Provider>
}

export default Context