import React, { useEffect, useState } from "react";
import { Store } from "react-notifications-component";
import { Navigate } from "react-router-dom";
import useUser from "../../hooks/useUser"
import listCategoriasProductos from "../../services/categoriasProductos";
import { ListProductos } from "../modulos/productos/List";


export const Productos = () => {
    const { jwt, user, notification, logout } = useUser();
    const [searchField, setSearchField] = useState("");
    const [categorias, setCategorias] = useState("");

    const handleChange = (e) => {setSearchField(e.target.value); };

    const getCategorias = () => {
        listCategoriasProductos(jwt).then(categorias => {
            if(categorias===401){
                Store.addNotification({
                    ...notification,
                    id: " ",
                    title: "Ha habido un error",
                    message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
                    type: "danger",
                  });
                logout();         
            }else{
                setCategorias(categorias);
            }
        })
    };

    useEffect(() => {
        getCategorias();
    }, []);

    return (
    <div className="container-title">
     
      { !jwt ? <Navigate to="/login" /> : '' }

      <h5><span className="spanTitulo">Listado de categorias: </span></h5>

      <nav className="navbar navbar-light bg-light justify-content-around">

          <form className="form-inline buscador-completo">
          <input
            className="form-control mr-sm-2"
            type="search"
            onChange = {handleChange}
            placeholder="Buscar"
            aria-label="Buscar"/>
        </form>

        <hr />
      </nav>
        
     <ListProductos busqueda={searchField} listado={categorias} />
    </div>
    );
};