import React, { useEffect, useState } from "react";
import { Global } from "../../../helpers/Global";
import { Navigate, useNavigate } from "react-router-dom";
import { Store } from 'react-notifications-component';
import useUser from "../../../hooks/useUser"
import { Imagen } from "../imagenes";
import { LoadingSpinner } from "../../../helpers/LoadingSpinner";
import { Switch } from "@mui/material";


export const FormPerfil = () => {

  const navigate = useNavigate();

  const { jwt, isLogged, logout, user, setUser, avatar, setAvatar, notification, fetchAvatar} = useUser();
  const [imagenAvatar, setImagenAvatar] = useState("");

  const [ isLoading, setIsLoading ] = useState(false);
  
  const id = user.id;

  useEffect(() => {
    if (avatar) {
      setImagenAvatar(avatar)
     }
  }, [avatar, jwt])

  const [imagenes, setImagenes] = useState([]);

  const [nombre, setNombre] = useState("");
  const [telefono, setTelefono] = useState("");
  const [email, setEmail] = useState("");
  const [cambiarPassword, setCambiarPassword] = useState(false); 
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");

  const nombreOnchangeHandler = (evento) => {
    setNombre(evento.target.value);
  };

  const telefonoOnChangeHandler = (evento) => {
    setTelefono(evento.target.value);
  };

  const emailOnchangeHandler = (evento) => {
    setEmail(evento.target.value);
  };

  const cambiarPasswordOnChangeHandler = (evento) => {
    setCambiarPassword(evento.target.checked);
  }

  const passwordOnchangeHandler = (evento) => {
    setPassword(evento.target.value);
  }

  const passwordRepeatOnChangeHandler = (evento) => {
    setPasswordRepeat(evento.target.value);
  }

  const getPerfilAjaxPms = () => {
    fetch(Global.url + "usuario/" + id, {
      method: "GET",
      headers: { Authorization: "Bearer " + jwt },
    })
      .then((respuesta) => {
        if(respuesta.status === 401){
          Store.addNotification({
            ...notification,
            id: " ",
            title: "Ha habido un error",
            message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
            type: "danger",
          });
          logout()
        }else if(respuesta.status === 500){
          Store.addNotification({
            ...notification,
            id: " ",
            title: "Ha habido un error",
            message: "Ha ocurrido un error",
            type: "danger"
          })
        }

        return respuesta.json()
      })
      .then(
        (resultado_final) => {
          setNombre(resultado_final.data.nombre);
          setTelefono(resultado_final.data.telefono);
          setEmail(resultado_final.data.email);
          setImagenes(resultado_final.data.imagen);
        },
        (error) => {
        //  console.log(error);
        }
      );
  };
  
  
  useEffect(() => {
    getPerfilAjaxPms();
  }, [isLogged, jwt, avatar, setUser]);

  const [checked, setChecked] = useState(false);
  const handleChange = nextChecked => {
    setChecked(nextChecked);
  };

  const actualizaUsuario = async (evento) => {

    evento.preventDefault();
    setIsLoading(true);

    const formElement = document.querySelector(".formPerfil");
    const data = new FormData(formElement);

    
    let url = '';
    url = Global.url + "usuarios/update/" + user.id;

    await fetch(url, {
      method: "POST",
      body: data,
      headers: { Authorization: "Bearer " + jwt },
    })
      .then((respuesta) => {
        const data = respuesta.json()
        if(respuesta.status === 401){
          Store.addNotification({
            ...notification,
            id: " ",
            title: "Ha habido un error",
            message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
            type: "danger",
          });
          logout()
        }else if(respuesta.status === 400){
          data.then((value) => {
            Store.addNotification({
              ...notification,
              id: " ",
              title: "Ha habido un error",
              message: value.errors[0].message,
              type: "danger",
            })
          });
          setIsLoading(false);
        }
        else if(respuesta.status === 500){
          Store.addNotification({
            ...notification,
            id: " ",
            title: "Ha habido un error",
            message: "Ha ocurrido un error",
            type: "danger",
          })
        }
        setIsLoading(false);
        return data
      })
      .then(
        (datos) => {
            if (datos.status === 201 ) {
              const userObj = JSON.stringify(datos.data);
              localStorage.setItem('user', userObj);
            
              const imagen = datos.data.imagen; 
              if ( imagen.length > 0 && imagen[0].id !=="undefined"){
              }else{
                localStorage.removeItem('avatar');
                setAvatar("");
              }
              setUser(JSON.parse(userObj));
              setIsLoading(false);
            Store.addNotification({
              ...notification,
              id: " ",
              title: "Datos modificados",
              message: datos.message,
              type: "success",
            })
          }
        }
      ).catch(error => {
        console.error('There was an error!', error);
    });
  
  };

  useEffect(() => {
    if (!isLogged) {
      navigate('/login')
    }
  }, [isLogged, navigate])
    
  
  return (
    <div>
      { isLoading === true ? <LoadingSpinner /> : '' }
      { !isLogged ? <Navigate to="/login" /> : '' }
      
      <form className={isLoading ? "containerLoading" : "formPerfil"} >
        <h5><span className="spanTitulo">Mis datos: </span></h5>

          <Imagen imagenes={imagenes} numImagenes="1"  />

            <div className="col-md-12">
              <div className="form-group">
                <label className="labelForm" htmlFor="nombre">Nombre:</label>
                <input type="text" 
                   id="nombre" 
                   name="nombre" 
                   className="nombreInput form-control" 
                   value={nombre}
                  onChange={nombreOnchangeHandler} /> 
                </div>
              </div>
            <div className="col-md-12"> 
              <div className="form-group">
                  <label className="labelForm" htmlFor="email">E-mail:</label>
                  <input type="email" 
                    id="email" 
                    name="email" 
                    className="emailInput form-control" 
                    value={email}
                    onChange={emailOnchangeHandler} />
              </div>
            </div>
            <div className="col-md-12"> 
              <div className="form-group">
                  <label className="labelForm" htmlFor="telefono">Teléfono:</label>
                  <input type="tel" 
                    id="telefono" 
                    name="telefono" 
                    className="telefonoInput form-control" 
                    value={telefono}
                    onChange={telefonoOnChangeHandler} /></div>
                    <div className="col-md-12"> <input type="hidden" 
                    id="activo" 
                    name="activo" 
                    className="activoInput form-control" 
                    value='1'
                    />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <span className="form-pass spanTitulo">¿Cambiar contraseña?</span>
                  <Switch
                    checked={cambiarPassword}
                    onChange={cambiarPasswordOnChangeHandler}
                    inputProps={{"aria-label": "controlled"}}
                    className="form-check-input"
                    id="cambiar_password"
                    name="cambiar_password"
                  />
                </div>
              </div>
              { cambiarPassword && <>
              <div className="col-md-12">
                <div className="form-group">
                  <label className="labelForm" htmlFor="password">Contraseña:</label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    name="password"
                    placeholder="Introduce tu contraseña"
                    value={password}
                    onChange={passwordOnchangeHandler} 
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label className="labelForm" htmlFor="password_repeat">Repite contraseña:</label>
                  <input
                    type="password"
                    className="form-control"
                    id="password_repeat"
                    name="password_repeat"
                    placeholder="Repite la contraseña"
                    value={passwordRepeat}
                    onChange={passwordRepeatOnChangeHandler}
                  />
                </div>
              </div>
              </>}
              <div className="col-md-12"> 
            <div className="form-group form-actions">
              <button 
                className="btn btn-primary btn-lg btn-block boton-azulbio btn-azul boton-perfil"
                type="submit"
                onClick={actualizaUsuario}
              >Guardar</button><br />
              <button
                className="btn btn-primary btn-lg btn-block boton-azulbio btn-rojo boton-perfil"
                type="submit"
                onClick={logout}
              >Cerrar sesión</button>
              </div>
              </div>
      </form>  
    </div>
  );
};