import React, { useEffect, useState } from "react";
import { Navigate, NavLink } from "react-router-dom";
import { ListUser } from "../modulos/usuarios/List";
import listUser  from '../../services/users'
import useUser from "../../hooks/useUser"
import { Store } from 'react-notifications-component';

export const Usuarios = () => {
    const { jwt, user, notification, logout } = useUser();
    const [searchField, setSearchField] = useState("");
    const [usuarios, setUsuarios] = useState("");

    const rol = user.role;

    const handleChange = (e) => {setSearchField(e.target.value); };

    const getUsuarios = () => {
        const activo = 0;
        listUser(jwt, activo).then(usuarios => {
            if(usuarios===401){
                    Store.addNotification({
                      ...notification,
                      id: " ",
                      title: "Ha habido un error",
                      message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
                      type: "danger",
                    });
                logout();         
            }else{
                setUsuarios(usuarios);
            }
        })
    };

    useEffect(() => {
        getUsuarios();
    }, []);

    return (
    <div className="container-title">
     
      { !jwt ? <Navigate to="/login" /> : '' }
      { rol === "2" ? <Navigate to="/login" /> : ''  }

      <h5><span className="spanTitulo">Listado de personal</span></h5>

            <nav className="navbar navbar-light bg-light justify-content-around">
            <div className="form-group form-actions">
            <button className="btn btn-primary btn-lg btn-block boton-azulbio btn-azul boton-addUser" type="submit">
                <NavLink to="/usuarios/crear" className="addUser" >Añadir nuevo</NavLink>
            </button>

          <form className="form-inline">
          <input
            className="form-control mr-sm-2 buscador"
            type="search"
            onChange = {handleChange}
            placeholder="Buscar"
            aria-label="Buscar"/>
        </form>

        </div>
      </nav>
        
     <ListUser busqueda={searchField} listado={usuarios} />
    </div>
    );
};