const ENDPOINT = 'https://backend.biosecuritytrack.com/api/v1/tareas'

export default function listTareas ({jwt,data}) {
  console.log(data)
  return fetch(`${ENDPOINT}/list`, {
    method: 'POST',
    body: data,
    headers: { Authorization: "Bearer " + jwt }
  }).then(res => {
    const data = res.json()
    if(res.status === 401){
      return res.status;
    }
    return data
  }).then(res => {
    if(res === 401){
      return res;
    }else{
      return res.data
    }
  })
}