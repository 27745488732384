const ENDPOINT = 'https://backend.biosecuritytrack.com/api/v1'

export default function listProductos (jwt, idCategoria) {
    return fetch(`${ENDPOINT}/categorias-productos/` + idCategoria, {
      method: 'GET',
      headers: { Authorization: "Bearer " + jwt }
    }).then(res => {
      const data = res.json()
      if(res.status === 401){
        return res.status;
      }
      return data
    }).then(res => {
      if(res === 401){
        return res;
      }else{
        return res.data
      }
    })
  }