import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useUser from "../../../hooks/useUser";
import listInforme from "../../../services/informe";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { common } from "@mui/material/colors";
import { Store } from "react-notifications-component";
import { Global } from "../../../helpers/Global";

export const FichaInformes = () => {
  const datosURL = useParams();
  const idInforme = datosURL.id;

  const { jwt, user, notification, logout } = useUser();

  const [informe, setInforme] = useState("");

  const userRole = user.role;

  const navigate = useNavigate();


  const getInforme = () => {
    listInforme(jwt, idInforme).then((data) => {
      if (data === 401) {
        Store.addNotification({
          ...notification,
          id: " ",
          title: "Ha habido un error",
          message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
          type: "danger",
        });
        logout();
      } else if(data === 400){
        Store.addNotification({
          ...notification,
          id: " ",
          title: "Ha habido un error",
          message: "Ha habido un error. Por favor, vuelva a intentarlo.",
          type: "danger",
        });
        navigate('/categorias-informes')
      } else {
        setInforme(data);
      }
    });
  };

  useEffect(() => {
    getInforme();
  }, []);

  const verPdfInforme = (numPdf, idInforme, nombreInforme) => {
    const ENDPOINT = "https://backend.biosecuritytrack.com/api/v1";

    return fetch(`${ENDPOINT}/informe/view-pdf/` + numPdf + "/" + idInforme, {
      method: "GET",
      headers: { Authorization: "Bearer " + jwt },
    })
      .then((res) => {
        const data = res.blob();
        if (res.status === 401) {
          Store.addNotification({
            ...notification,
            id: " ",
            title: "Ha habido un error",
            message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
            type: "danger",
          });
          logout();
        }
        return data;
      })
      .then((res) => {
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(res);
        link.download = nombreInforme;
        link.click();
      });
  };

  const eliminarInforme = async (informe) => {
    if (window.confirm("Vas a borrar el informe. ¿Quieres continuar?")){
          const request = await fetch(Global.url + "informes/delete/" + informe, {
            method: "DELETE",
            headers: { Authorization: "Bearer " + jwt },
          })
          .then((respuesta) => {
            const data = respuesta.json()
            if(respuesta.status === 401){
              Store.addNotification({
                ...notification,
                id: " ",
                title: "Ha habido un error",
                message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
                type: "danger",
              });
            logout()
          } else if(respuesta.status === 400){
            data.then((value) => {
              Store.addNotification({
                ...notification,
                id: " ",
                title: "Ha habido un error",
                message: value.errors[0].message,
                type: "danger",
              })
            });
          } else if(respuesta.status === 500){
            Store.addNotification({
              ...notification,
              id: " ",
              title: "Ha habido un error",
              message: "Ha ocurrido un error",
              type: "danger",
            })
          }
          return data
          })
          .then((datos) => {
            if (datos.status === 201) {
             
              Store.addNotification({
                ...notification,
                id: " ",
                title: "Informe borrado",
                message: "Informe eliminado correctamente.",
                type: "success",
              })
              navigate("/categorias-informes");
          }  
          });
    }
};

  return (
    <div className="container">
      <div className="col-md-12">
        <span className="spanProducto"><span className="tituloProducto">Nombre:</span> {informe.nombre}</span>
      </div> 
      {informe.descripcion && (
        <div className="col-md-12">
          <span className="spanProducto"><span className="tituloProducto">Descripción:</span> {informe.descripcion}</span>
        </div>
      )}
      <div className="col-md-12">
        <br />
        <span>Pulsa para descargar los archivos</span>
        <br/>
        <br/>
        <div className="form-group form-actions">
            {informe.pdf_1 && (
              <>
                <button className="btn btn-primary btn-lg btn-block boton-azulbio btn-azul btn-producto"
                  onClick={(e) =>
                    verPdfInforme(1, informe.id, informe.pdf_1)
                  }
                >
                  <span className="botonPdf">{informe.pdf_1} </span>
                  <PictureAsPdfIcon
                    sx={{ fontSize: 15, color: common.white }}
                  />
                </button>
              </>
            )}
            </div>
            <div className="form-group form-actions">
         
            {informe.pdf_2 && (
              <>
                <button className="btn btn-primary btn-lg btn-block boton-azulbio btn-azul btn-producto"
                  onClick={(e) =>
                    verPdfInforme(2, informe.id, informe.pdf_2)
                  }
                ><span className="botonPdf">{informe.pdf_2} </span>
                  <PictureAsPdfIcon
                    sx={{ fontSize: 15, color: common.white }}
                  />
                </button>
              </>
            )}

               { userRole === '1' ? ( <button className="btn btn-primary btn-lg btn-block boton-azulbio btn-rojo btn-producto"
                  onClick={(e) => eliminarInforme(informe.id)}
                >Borrar informe</button>) : ('')}
        </div>
      </div>
    </div>
  );
};
