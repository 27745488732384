import {Buffer} from 'buffer';

const ENDPOINT = 'https://backend.biosecuritytrack.com/api/v1/imagen/view'

export default async function avatar ({ token, idAvatar }) {
 

  const image = await fetch(`${ENDPOINT}/` + idAvatar, {
    method: 'GET',
    headers: { Authorization: "Bearer " + token }
  });

  const contentType = image.headers.get('Content-type')

  const imageBuffer = await image.arrayBuffer()

  return `data:${contentType};base64,${Buffer.from(imageBuffer).toString(
    'base64'
  )}`
}



   
    