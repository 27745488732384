import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import useUser from "../../../hooks/useUser"
import ListInformes from "../../../services/informes";
import FileCopyIcon from '@mui/icons-material/FileCopy';

import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { Store } from "react-notifications-component";

import listGranjas from '../../../services/granjas';

import listInformesGranjas from "../../../services/informesGranjas";

export const EditInformes = () => {
  const { jwt, notification, logout, isLogged } = useUser();

  const datosURL = useParams();
  const idInforme = datosURL.id;

  const [informes, setInformes] = useState('');
  const [categoria, setCategoria] = useState('');

  const [granjas, setGranjas] = useState('');

  const [granjaInforme, setGranjaInforme] = useState('');

  const granjaInformeOnchangeHandler = (evento) => {
    setGranjaInforme(evento.target.value);
  }

  const getGranjas = () => {
    listGranjas(jwt).then(granjas => {
      if (granjas === 401) {
        Store.addNotification({
          ...notification,
          id: " ",
          title: "Ha habido un error",
          message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
          type: "danger",
        });
        logout();
      } else {
        setGranjas(granjas);
      }
    })
  }

  useEffect(() => {
    getGranjas();
  }, [])

  useEffect(() => {
    actualizaInforme();
  }, [jwt, isLogged]);


  const actualizaInforme = async (evento = {}) => {

    if (typeof evento.preventDefault === 'function') {
      evento.preventDefault();
    }

    const formElement = document.querySelector('.formInformes');
    const data = new FormData(formElement);

    listInformesGranjas(jwt, idInforme, data).then(data => {
      if (data === 401) {
        Store.addNotification({
          ...notification,
          id: " ",
          title: "Ha habido un error",
          message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
          type: "danger",
        });
        logout();
      } else {
        if (data.informes) {
          setInformes(data.informes);
          setCategoria(data.categoria.nombre);
        } else {
          setInformes("");
        }
      }
    })
  }


  return (
    <div className="container-title">

      {categoria && <h5><span className="spanCategoriaTitulo">Categoría seleccionada: </span><span className="spanCategoria">{categoria}</span></h5>}
      <h5><span className="spanTitulo">Listado de informes: </span></h5>
      {/*Añadir Filtro de Granjas aqui*/}

      <div className='containerCalendario'>

        <form className='formInformes'>
          <div className="col-md-12">
            <div className="form-group">
              <label className="labelForm" htmlFor="id_granja">Seleccione granja:</label>
              <select className="form-select form-select form-control"
                aria-label=".form-select-sm example"
                id="granja"
                name="granja"
                value={granjaInforme}
                onChange={granjaInformeOnchangeHandler}>
                <option key="granja-0" value="">Seleccione</option>
                {
                  granjas && granjas.map(granja => {
                    return <option key={granja.id} value={granja.id}>{granja.nombre}</option>
                  })
                }
              </select>
            </div></div>
          <br />

          <button
            className="btn btn-primary btn-lg btn-block boton-azulbio btn-azul"
            type="submit"
            onClick={actualizaInforme}
          >
            Consultar
          </button>
          <br />  <br />
        </form>
      </div>

      {informes ?
        informes.map((informe, index) => {
          return (
            <div className="">
              <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', fontVariant: 'h6' }} key={`informeList ${informe.id}`} >
                <Link to={"/informe/" + informe.id} >
                  <ListItem key={`informeItem ${informe.id}`}>
                    <ListItemAvatar >
                      <Avatar >
                        <FileCopyIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={informe.nombre} secondary={informe.descripcion} />
                  </ListItem>
                </Link>
              </List>
            </div>
          );
        }) : 'No hay informes disponibles.'}
    </div>
  );
};

