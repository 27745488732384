import React, { useEffect, useState } from "react";
import { Global } from "../../helpers/Global";
import { Navigate, useNavigate } from "react-router-dom";
import { Store } from "react-notifications-component";
import useUser from "../../hooks/useUser";
import { Chart } from "react-google-charts";
import listGranjas from "../../services/granjas";
import listNaves from "../../services/naves";

export const Evolucion = () => {

  const navigate = useNavigate();

  const {
    jwt,
    isLogged,
    logout,
    user,
    setUser,
    avatar,
    setAvatar,
    notification,
  } = useUser();

  const [data, setData] = useState([]);
  const [idTipoFormulario, setIdTipoFormulario] = useState("");
  const [year, setYear] = useState(new Date().getFullYear());
  const [granjas, setGranjas] = useState("");
  const [naves, setNaves] = useState("");
  const [granjaFormulario, setGranjaFormulario] = useState("");
  const [naveFormulario, setNaveFormulario] = useState("");

  const idTipoFormularioOnChangeHandler = (evento) => {
    setIdTipoFormulario(evento.target.value);
  };

  const yearOnChangeHandler = (evento) => {
    setYear(evento.target.value);
  };

  const granjaFormularioOnChangeHandler = (evento) => {
    setGranjaFormulario(evento.target.value);
    getNaves(evento.target.value);
  };

  const naveFormularioOnChangeHandler = (evento) => {
    setNaveFormulario(evento.target.value);
  };

  const ArrayYears = () => {
    let listYears = [];
    for(let i = 2023; i < 2099; i++) {
      listYears.push(i);
    }
    return listYears;
  }

  useEffect(() => {
    actualizaEvolucion();
  }, [jwt, isLogged]);

  const getGranjas = () => {
    listGranjas(jwt).then((granjas) => {
      if (granjas === 401) {
        Store.addNotification({
          ...notification,
          id: " ",
          title: "Ha habido un error",
          message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
          type: "danger",
        });
        logout();
      } else {
        setGranjas(granjas);
      }
    });
  };

  const getNaves = (idGranja) => {
    listNaves(jwt, idGranja).then((naves) => {
      if (naves === 401) {
        Store.addNotification({
          ...notification,
          id: " ",
          title: "Ha habido un error",
          message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
          type: "danger",
        });
        logout();
      } else {
        setNaves(naves);
      }
    });
  };

  useEffect(() => {
    getGranjas();
  }, []);

  const actualizaEvolucion = async (evento = {}) => {

    if (typeof evento.preventDefault === "function") { 
      evento.preventDefault();
    }

    const formElement = document.querySelector(".formEvolucion");
    const data = new FormData(formElement);

    let url = "";
    url = Global.url + "formularios/stats";

    await fetch(url, {
      method: "POST",
      body: data,
      headers: { Authorization: "Bearer " + jwt },
    })
      .then((respuesta) => {
        const data = respuesta.json();
        if (respuesta.status === 401) {
          Store.addNotification({
            ...notification,
            id: " ",
            title: "Ha habido un error",
            message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
            type: "danger",
          });
          logout();
        } else if (respuesta.status === 400) {
          data.then((value) => {
            Store.addNotification({
              ...notification,
              id: " ",
              title: "Ha habido un error",
              message: value.errors[0].message,
              type: "danger",
            });
          });
        } else if (respuesta.status === 500) {
          Store.addNotification({
            ...notification,
            id: " ",
            title: "Ha habido un error",
            message: "Ha ocurrido un error",
            type: "danger",
          });
        }

        return data;
      })
      .then((datos) => {
        if (datos.status === 201) {
           const dataArray = [];
           datos.data.datos.map((index, row) =>{      
             if(row == 0){
               dataArray.push([
                 index[0],
                 index[1]
               ]);
             }else{
               dataArray.push([
                 index[0],
                 parseFloat(index[1])
               ]);
             }
           });
           setData(dataArray);
        }
      })
      .catch((error) => {
       // console.error("There was an error!", error);
      });
  };

  useEffect(() => {
    if (!isLogged) {
      navigate("/login");
    }
  }, [isLogged, navigate]);
  
  const options = {
    title: "Evolución",
    legend: { position: "bottom" },
  };

  return (
    <div className="contenedorEvolucion">
      {!isLogged ? <Navigate to="/login" /> : ""}

      <form className="formEvolucion">
        <h5><span className="spanTitulo">Evolución Bioseguridad</span></h5>

        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="year" className="labelForm">Año:</label>
            <select
                  className="form-select form-select form-control"
                  aria-label=".form-select-sm example"
                  id="year"
                  name="year"
                  value={year}
                  onChange={yearOnChangeHandler}
                >
                  <option key="year-0" value="">Seleccione</option>
                  {ArrayYears().map((YearItem) => {
                        return (
                          <option
                            key={YearItem}
                            value={YearItem}
                          >
                            {YearItem}
                          </option>
                        );
                      })}
            </select>
          </div>
        </div>

        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="id_tipo_formulario" className="labelForm">Tipo:</label>
            <select
                  className="form-select form-select form-control"
                  aria-label=".form-select-sm example"
                  id="id_tipo_formulario"
                  name="tipo_formulario"
                  value={idTipoFormulario}
                  onChange={idTipoFormularioOnChangeHandler}
                >
                  <option key="tipos-todos-0" value="">Bioseguridad General</option>
                  <option key="1" value="1">
                    Bioseguridad Externa
                  </option>
                  <option key="2" value="2">
                    Bioseguridad Interna
                  </option>
            </select>
          </div>
        </div>

        <div className="col-md-12">
          <div className="form-group">
            <label className="labelForm" htmlFor="id_granja">
              Granja:
            </label>
              <select
                className="form-select form-select form-control"
                aria-label=".form-select-sm example"
                id="id_granja"
                name="granja"
                value={granjaFormulario}
                onChange={granjaFormularioOnChangeHandler}
              >
                <option key="granja-select0" value="">Selecciona granja</option>
                {granjas &&
                  granjas.map((granja) => {
                    return (
                      <option key={granja.id} value={granja.id}>
                        {granja.nombre}
                      </option>
                    );
                  })}
              </select>
              
          </div>
        </div>

        {idTipoFormulario === "2" ? (            
        <div className="col-md-12">
          <div className="form-group">
            <label className="labelForm" htmlFor="id_nave">
              Nave:
            </label>
              <select
                className="form-select form-select form-control"
                aria-label=".form-select-sm example"
                id="id_nave"
                name="nave"
                value={naveFormulario}
                onChange={naveFormularioOnChangeHandler}
              >
                <option key="nave-select0" value="">Selecciona nave</option>
                {naves &&
                  naves.map((nave) => {
                    return (
                      <option key={nave.id} value={nave.id}>
                        {nave.nombre}
                      </option>
                    );
                  })}
              </select>
          </div>
        </div>
        ) : (
          ""
        )}
        <br /> 
        <button
          className="btn btn-primary btn-lg btn-block boton-azulbio btn-azul"
          type="submit"
          onClick={actualizaEvolucion}
        >
          Consultar
        </button>

        <br /> <br /> 
        <Chart
          chartType="LineChart"
          width="100%"
          height="400px"
          data={data}
          options={options}
        />
        <br /> <br /> 
      </form>
    </div>
  );
};