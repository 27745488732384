import React, { useCallback, useEffect, useRef, useState } from "react";
import { Navigate, NavLink } from "react-router-dom";
import useUser from "../../hooks/useUser"
import { ListForms } from "../modulos/formularios/List";
import listForms from "../../services/formularios";
import { Store } from "react-notifications-component";

export const Formularios = () => {

    const { jwt, user, notification, logout } = useUser();
    const [searchField, setSearchField] = useState("");
    
    const [ formularios, setFormularios ] = useState('');
    
    const handleChange = (e) => {setSearchField(e.target.value); };

    const getFormularios = () => {
        listForms(jwt).then(formularios => {
            if(formularios===401){
                Store.addNotification({
                    ...notification,
                    id: " ",
                    title: "Ha habido un error",
                    message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
                    type: "danger",
                  });
                logout();         
            }else{
                setFormularios(formularios);
            }
        })
    };

    useEffect(() => {
        getFormularios();
    }, []);

    return (
    <div className="container-title">
     
      { !jwt ? <Navigate to="/login" /> : '' }
     
      <h5><span className="spanTitulo">Listado de formularios: </span></h5>

            <nav className="navbar navbar-light bg-light justify-content-around">
            <div className="form-group form-actions">
            <button className="btn btn-primary btn-lg btn-block boton-azulbio btn-azul boton-addUser" type="submit">
                <NavLink to="/formularios/crear" className="addform" >Añadir nuevo</NavLink>
            </button>

          <form className="form-inline">
          <input
            className="form-control mr-sm-2 buscador"
            type="search"
            onChange = {handleChange}
            placeholder="Buscar"
            aria-label="Buscar"/>
        </form>

        </div>        
      </nav>
        
     <ListForms busqueda={searchField} listado={formularios} />
    </div>
    );
};