import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Global } from "../../../helpers/Global";
import Switch from "@mui/material/Switch";
import { Store } from 'react-notifications-component';
import useUser from "../../../hooks/useUser";
import {Imagen} from "../imagenes/index"
import listUser from "../../../services/users";
import listGranjas from "../../../services/granjas";
import { LoadingSpinner } from "../../../helpers/LoadingSpinner";


export const FormGranjas = ({ accion, granjaId }) => {  

  const {jwt, isLogged, notification, user, logout} = useUser();

  const [nombre, setNombre] = useState("");
  const [activo, setActivo ] = useState("");
  const [veterinario, setVeterinario] = useState("");
  const [rega, setRega] = useState("");
  const [localizacion, setLocalizacion ] = useState("");
  const [ganadero, setGanadero ] = useState("");
  const [ganaderoEmail, setGanaderoEmail] = useState("");
  const [ ganaderoTlf, setGanaderoTlf] = useState("");
  const [usuarios, setUsuarios] = useState("");
  const [granjas, setGranjas ] = useState("")

  const [ isLoading, setIsLoading ] = useState(false);


  const [imagenes, setImagenes] = useState([]);

  const [titleForm, setTitleForm] = useState(accion);

  const [checked, setChecked] = React.useState(true);

  const navigate = useNavigate();
  const userRole = user.role;

  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked === true ) {
      setActivo('1')
    } else if (event.target.checked === false ) {
      setActivo('0')
    }
  };

  const nombreOnchangeHandler = (evento) => {
    setNombre(evento.target.value);
  };

  const veterinarioOnChangeHandler = (evento) => {
    setVeterinario(evento.target.value);
  }

  const regaOnChangeHandler = (evento) => {
    setRega(evento.target.value);
  };

  const localizacionOnChangeHandler = (evento) => {
    setLocalizacion(evento.target.value);
  };

  const ganaderoOnChangeHandler = (evento) => {
    setGanadero(evento.target.value);
  };

  const ganaderoEmailOnChangeHandler = (evento) => {
    setGanaderoEmail(evento.target.value);
  };

  const ganaderoTlfOnChangeHandler = (evento) => {
    setGanaderoTlf(evento.target.value);
  };

  const submitGranja = async (evento) => {
    
    evento.preventDefault();
    setIsLoading(true);

    const formElement = document.querySelector(".granjaEdit");
    const data = new FormData(formElement);

    let url = "";

    if (accion === "update") {
      url = Global.url + "granjas/" + accion + "/" + granjaId;
    } else {
      url = Global.url + "granjas/create";
    }
    
    await fetch(url, {
      method: "POST",
      body: data,
      headers: { Authorization: "Bearer " + jwt },
    })
      .then((respuesta) => {
        const data = respuesta.json()
        if(respuesta.status === 401){
          Store.addNotification({
            ...notification,
            id: " ",
            title: "Ha habido un error",
            message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
            type: "danger",
          });
          logout()
        }else if(respuesta.status === 400){
          data.then((value) => {
            Store.addNotification({
              ...notification,
              id: " ",
              title: "Ha habido un error",
              message: value.errors[0].message,
              type: "danger",
            })
          });
          setIsLoading(false);
        }
        else if(respuesta.status === 500){
          Store.addNotification({
            ...notification,
            id: " ",
            title: "Ha habido un error",
            message: "Ha ocurrido un error",
            type: "danger",
          })
        }
        setIsLoading(false);
        return data; 
      })
      .then(
        (datos) => {
          if (datos.status === 201 ) {
              Store.addNotification({
                ...notification,
                id: " ",
                title:  datos.message,
                message: datos.message,
                type: "success",
              });
              setIsLoading(false);
              navigate("/granjas");
          }

        }
      ).catch(error => {
        console.error('There was an error!', error);
    });
    
  };


  const getGranjaData = () => {
    fetch(Global.url + "granja/" + granjaId, {
      method: "GET",
      headers: { Authorization: "Bearer " + jwt },
    })
    .then((respuesta) => {
      if(respuesta.status === 401){
        Store.addNotification({
          ...notification,
          id: " ",
          title: "Ha habido un error",
          message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
          type: "danger",
        });
        logout()
      }else if(respuesta.status === 400){
        Store.addNotification({
          ...notification,
          id: " ",
          title: "Ha habido un error",
          message: "Ha ocurrido un error",
          type: "danger"
        })
        navigate('/granjas')
      }

      return respuesta.json()
    })
      .then(
        (resultado_final) => {
          setNombre(resultado_final.data.nombre);
          setRega(resultado_final.data.rega)
          setLocalizacion(resultado_final.data.localizacion)
          setGanadero(resultado_final.data.ganadero)
          setGanaderoEmail(resultado_final.data.ganadero_email)
          setGanaderoTlf(resultado_final.data.ganadero_tlf)
          setImagenes(resultado_final.data.imagen);
          setVeterinario(resultado_final.data.id_usuario);
          setChecked(() => {
            if(resultado_final.data.activo === "0"){
              return false;        
            }else{
              return true;
            }
          })
        },
        (error) => {
         // console.log(error);
        }
      );
  };

 const getUsuarios = () => {
  listUser(jwt).then(usuarios => {
      if(usuarios===401){
        Store.addNotification({
          ...notification,
          id: " ",
          title: "Ha habido un error",
          message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
          type: "danger",
        });
          logout();         
      }else{
          setUsuarios(usuarios);
      }
  })
};

  useEffect(() => {
    if(accion === "update"){
      getGranjaData();
    } 
  },[accion])

  useEffect(() => {
    if (userRole === '1')
    getUsuarios();
  }, [])
 
  return (
    <div>
      { isLoading === true ? <LoadingSpinner/> : '' }
      {!isLogged ? <Navigate to="/login" /> : ""}

      <div className={isLoading ? "containerLoading" : "container"}>
        <h5 className="formTitle">
          {titleForm === "update" ? (
            <span className="spanTitulo">Actualizar granja:</span>
          ) : (
            <span className="spanTitulo">Añadir granja:</span>
          )}
        </h5>
        <div className="contenedor-formulario container">
          <form className="row g-3 granjaEdit" onSubmit={submitGranja}>
            {titleForm === "update" ? (
              <div className="col-md-12">
                <div className="form-group">
                <span className="form-pass spanTitulo">Activo</span>
                <Switch
                  value={checked}
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                  className="form-check-input"
                  id="activo"
                  name="activo"
                />
              </div>
              </div>
            ) : (
              ""
            )}

            <div className="col-md-12">
            <div className="form-group">
                <label className="labelForm" htmlFor="nombre">Nombre: *</label>
              <input
                type="nombre"
                className="form-control"
                id="nombre"
                name="nombre"
                placeholder="Introduzca nombre de granja"
                value={nombre}
                onChange={nombreOnchangeHandler}
              />
            </div>
            </div>
            { userRole === '1' ? (<div className="col-md-12">
            <div className="form-group">
                <label className="labelForm" htmlFor="id_usuario">Selecciona persona responsable: *</label>
                <select className="form-select form-select form-control" 
                        aria-label=".form-select-sm example"
                        id="id_usuario"
                        name="id_usuario"
                        value={veterinario}
                        onChange={veterinarioOnChangeHandler}>
                  <option >Selecciona persona responsable</option>
                  {
                    usuarios && usuarios.map(usuario => {
                      return <option key={usuario.id} value={usuario.id}>{usuario.nombre}</option> 
                    })
                  }
                </select>
              </div>
              </div>) : (<div className="col-md-12"><div className="form-group"><input type="hidden" value={user.id} id="id_usuario" name="id_usuario"  /></div></div>)}

             <div className="col-md-12">
             <div className="form-group">
                <label className="labelForm" htmlFor="rega">Número identificativo de explotación:</label>
             <input
                type="text"
                className="form-control regaInput"
                id="rega"
                name="rega"
                placeholder="Introduzca número identificativo de explotación"
                value={rega}
                onChange={regaOnChangeHandler}
             />
            </div></div>

            <div className="col-md-12">
            <div className="form-group">
                <label className="labelForm" htmlFor="localizacion">Localización:</label>
              <input
                type="text"
                className="form-control"
                id="localizacion"
                name="localizacion"
                placeholder="Introduzca localización de granja"
                value={localizacion}
                onChange={localizacionOnChangeHandler}
              />
            </div></div>

            <div className="col-md-12">
            <div className="form-group">
                <label className="labelForm" htmlFor="ganadero">Nombre del propietario:</label>
              <input
                type="text"
                className="form-control"
                id="ganadero"
                name="ganadero"
                placeholder="Nombre del propietario"
                value={ganadero}
                onChange={ganaderoOnChangeHandler}
              />
            </div></div>

            <div className="col-md-12">
            <div className="form-group">
                <label className="labelForm" htmlFor="ganadero_email">E-mail del propietario:</label>
              <input
                type="email"
                className="form-control"
                id="ganadero_email"
                name="ganadero_email"
                placeholder="Introduzca email del propietario"
                value={ganaderoEmail}
                onChange={ganaderoEmailOnChangeHandler}
              />
            </div></div>

            <div className="col-md-12">
            <div className="form-group">
                <label className="labelForm" htmlFor="ganadero_tlf">Teléfono del propietario:</label>
              <input
                type="tel"
                className="form-control"
                id="ganadero_tlf"
                name="ganadero_tlf"
                placeholder="Introduzca teléfono del propietario"
                value={ganaderoTlf}
                onChange={ganaderoTlfOnChangeHandler}
              />
            </div></div>            

            <Imagen imagenes={imagenes} numImagenes="5"  />

            
                {titleForm === "update" ? (
                 <div className="col-12">
                 
                 <button
                   type="submit"
                   className="btn btn-primary btn-lg btn-block boton-azulbio btn-azul"
                   disabled={isLoading}
                 >Guardar</button></div>
                ) : (
                  <div className="col-12">
                    
                 <button
                   type="submit"
                   className="btn btn-primary btn-lg btn-block boton-azulbio btn-azul"
                   disabled={isLoading}
                 >Crear</button></div>
                )}
              
          </form>
        </div>
      </div>
    </div>
  );
};
