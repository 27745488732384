import React, { useEffect, useState } from "react";
import { Global } from "../../helpers/Global";
import { Navigate, useNavigate } from "react-router-dom";
import { Store } from "react-notifications-component";
import useUser from "../../hooks/useUser";
import { Imagen } from "../modulos/imagenes";
import listProvincias from "../../services/provincias";

export const MiEmpresa = () => {
  const navigate = useNavigate();

  const {
    jwt,
    isLogged,
    logout,
    user,
    setUser,
    avatar,
    setAvatar,
    notification,
  } = useUser();

  const [imagenes, setImagenes] = useState([]);
  const [nombre, setNombre] = useState("");
  const [apellidos, setApellidos] = useState("");
  const [nif_representante, setNif_representante] = useState("");
  const [web, setWeb] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [marca_comercial, setMarca_comercial] = useState("");
  const [cif, setCif] = useState("");
  const [telefono, setTelefono] = useState("");
  const [telefono2, setTelefono2] = useState("");
  const [fax, setFax] = useState("");
  const [email, setEmail] = useState("");
  const [direccion, setDireccion] = useState("");
  const [codpost, setCodpost] = useState("");
  const [idProvincia, setIdProvincia] = useState("");
  const [provincias, setProvincias] = useState();

  const nombreOnchangeHandler = (evento) => {
    setNombre(evento.target.value);
  }

  const apellidosOnchangeHandler = (evento) => {
    setApellidos(evento.target.value);
  }

  const nif_representanteOnchangeHandler = (evento) => {
    setNif_representante(evento.target.value);
  }

  const webOnchangeHandler = (evento) => {
    setWeb(evento.target.value);
  }

  const empresaOnchangeHandler = (evento) => {
    setEmpresa(evento.target.value);
  }

  const marcaComercialOnchangeHandler = (evento) => {
    setMarca_comercial(evento.target.value);
  }

  const cifOnchangeHandler = (evento) => {
    setCif(evento.target.value);
  }

  const telefonoOnChangeHandler = (evento) => {
    setTelefono(evento.target.value);
  }

  const telefono2OnChangeHandler = (evento) => {
    setTelefono2(evento.target.value);
  }

  const faxOnChangeHandler = (evento) => {
    setFax(evento.target.value);
  }

  const emailOnchangeHandler = (evento) => {
    setEmail(evento.target.value);
  }

  const direccionOnchangeHandler = (evento) => {
    setDireccion(evento.target.value);
  }

  const codpostOnchangeHandler = (evento) => {
    setCodpost(evento.target.value);
  }

  const idProvinciaOnChangeHandler = (evento) => {
    setIdProvincia(evento.target.value)
  }
  
  const [imagenAvatar, setImagenAvatar] = useState("");

useEffect(() => {
  if (avatar) {
    setImagenAvatar(avatar)
   }
}, [avatar, jwt])

const getEmpresa = () => {
  fetch(Global.url + "empresa", {
    method: "GET",
    headers: { Authorization: "Bearer " + jwt },
  })
  .then((respuesta) => {
    if(respuesta.status === 401){
      Store.addNotification({
        ...notification,
        id: " ",
        title: "Ha habido un error",
        message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
        type: "danger",
      });
      logout()
    }else if(respuesta.status === 500){
      Store.addNotification({
        ...notification,
        id: " ",
        title: "Ha habido un error",
        message: "Ha ocurrido un error",
        type: "danger"
      })
    }

    return respuesta.json()
  })
    .then(
      (resultado_final) => {
        setNombre(resultado_final.data.nombre)
        setApellidos(resultado_final.data.apellidos)
        setNif_representante(resultado_final.data.nif_representante)
        setWeb(resultado_final.data.web)
        setEmpresa(resultado_final.data.empresa)
        setMarca_comercial(resultado_final.data.marca_comercial)
        setCif(resultado_final.data.cif)
        setTelefono(resultado_final.data.telefono)
        setTelefono2(resultado_final.data.telefono2)
        setFax(resultado_final.data.fax)
        setEmail(resultado_final.data.email)
        setDireccion(resultado_final.data.direccion)
        setCodpost(resultado_final.data.cp)
        setImagenes(resultado_final.data.imagen);
        setIdProvincia(resultado_final.data.id_provincia)
      },
      (error) => {
        //console.log(error);
      }
    );
};

  useEffect(() => {
    getEmpresa();
  }, [jwt, isLogged]);

  const actualizaEmpresa = async (evento) => {
    evento.preventDefault();

    const formElement = document.querySelector(".formEmpresa");
    const data = new FormData(formElement);

    let url = "";
    url = Global.url + "empresa/update";

    await fetch(url, {
      method: "POST",
      body: data,
      headers: { Authorization: "Bearer " + jwt },
    })
      .then((respuesta) => {
        const data = respuesta.json();
        if (respuesta.status === 401) {
          Store.addNotification({
            ...notification,
            id: " ",
            title: "Ha habido un error",
            message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
            type: "danger",
          });
          logout();
        } else if (respuesta.status === 400) {
          data.then((value) => {
            Store.addNotification({
              ...notification,
              id: " ",
              title: "Ha habido un error",
              message: value.errors[0].message,
              type: "danger",
            });
          });
        } else if (respuesta.status === 500) {
          Store.addNotification({
            ...notification,
            id: " ",
            title: "Ha habido un error",
            message: "Ha ocurrido un error",
            type: "danger",
          });
        }

        return data;
      })
      .then((datos) => {
        if (datos.status === 201) {
          Store.addNotification({
            ...notification,
            id: " ",
            title: "Datos modificados",
            message: datos.message,
            type: "success",
          });
          setTimeout(() => {
            navigate('/dashboard')
          },1500)
          
        }
      })
      .catch((error) => {
        //console.error("There was an error!", error);
      });
  };

  useEffect(() => {
    if (!isLogged) {
      navigate("/login");
    }
  }, [isLogged, navigate]);

  const getProvincias = () => {
    listProvincias(jwt).then(provincias => {
        if(provincias===401){
          Store.addNotification({
            ...notification,
            id: " ",
            title: "Ha habido un error",
            message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
            type: "danger",
          });
            logout();         
        }else{
            setProvincias(provincias);
        }
    })
  };
   useEffect(() => {
    getProvincias();
    }, [])
  

  return (
    <div>
      {!isLogged ? <Navigate to="/login" /> : ""}

      <form className="formEmpresa">
        <h5><span className="spanTitulo">Mi empresa: </span></h5>

        <Imagen imagenes={imagenes} numImagenes="1" />         

        <div className="col-md-12">
        <div className="form-group">
        <label htmlFor="nombre" className="labelForm">Nombre:</label>
          <input
            type="text"
            id="nombre"
            name="nombre"
            className="nombreInput form-control"
            value={nombre}
            onChange={nombreOnchangeHandler}
          />
        </div>
        </div>

        <div className="col-md-12">
        <div className="form-group">
        <label htmlFor="apellidos" className="labelForm">Apellidos:</label>
          <input
            type="text"
            id="apellidos"
            name="apellidos"
            className="apellidosInput form-control"
            value={apellidos}
            onChange={apellidosOnchangeHandler}
          />
        </div></div>

        <div className="col-md-12">
        <div className="form-group">
        <label htmlFor="nif_representante" className="labelForm">Nif representante:</label>
          <input
            type="text"
            id="nif_representante"
            name="nif_representante"
            className="nifInput form-control"
            value={nif_representante}
            onChange={nif_representanteOnchangeHandler}
          />
        </div></div>

        <div className="col-md-12">
        <div className="form-group">
        <label htmlFor="web" className="labelForm">Web:</label>
          <input
            type="text"
            id="web"
            name="web"
            className="webInput form-control"
            value={web}
            onChange={webOnchangeHandler}
          />
        </div></div>

        <div className="col-md-12">
        <div className="form-group">
        <label htmlFor="empresa" className="labelForm">Empresa: *</label>
          <input
            type="text"
            id="empresa"
            name="empresa"
            className="empresaInput form-control"
            value={empresa}
            onChange={empresaOnchangeHandler}
          />
        </div></div>

        <div className="col-md-12">
        <div className="form-group">
        <label htmlFor="marca_comercial" className="labelForm">Marca comercial: *</label>
          <input
            type="text"
            id="marca_comercial"
            name="marca_comercial"
            className="marcaComercialInput form-control"
            value={marca_comercial}
            onChange={marcaComercialOnchangeHandler}
          />
        </div></div>

        <div className="col-md-12">
        <div className="form-group" >
        <label htmlFor="cif" className="labelForm">CIF:</label>
          <input
            type="text"
            id="cif"
            name="cif"
            className="cifInput form-control"
            value={cif}
            onChange={cifOnchangeHandler}
          />
        </div></div>

        <div className="col-md-12">
        <div className="form-group">
        <label htmlFor="telefono" className="labelForm">Telefono: *</label>
          <input
            type="tel"
            id="telefono"
            name="telefono"
            className="telefonoInput form-control"
            value={telefono}
            onChange={telefonoOnChangeHandler}
          />
        </div></div>

        <div className="col-md-12">
        <div className="form-group">
        <label htmlFor="telefono2" className="labelForm">Telefono 2:</label>
          <input
            type="tel"
            id="telefono2"
            name="telefono2"
            className="telefonoInput form-control"
            value={telefono2}
            onChange={telefono2OnChangeHandler}
          />
        </div></div>

        <div className="col-md-12">
        <div className="form-group">
        <label htmlFor="fax" className="labelForm">Fax:</label>
          <input
            type="text"
            id="fax"
            name="fax"
            className="faxInput form-control"
            value={fax}
            onChange={faxOnChangeHandler}
          />
        </div></div>

        <div className="col-md-12">
        <div className="form-group">
        <label htmlFor="email" className="labelForm">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            className="emailInput form-control"
            value={email}
            onChange={emailOnchangeHandler}
          />
        </div></div>

        <div className="col-md-12">
        <div className="form-group">
        <label htmlFor="direccion" className="labelForm">Dirección:</label>
          <input
            type="text"
            id="direccion"
            name="direccion"
            className="direccionInput form-control"
            value={direccion}
            onChange={direccionOnchangeHandler}
          />
        </div></div>


        <div className="col-md-12">
        <div className="form-group">
        <label htmlFor="cp" className="labelForm">Código postal:</label>
          <input
            type="text"
            id="cp"
            name="cp"
            className="codpostInput form-control"
            value={codpost}
            onChange={codpostOnchangeHandler}
          />
        </div></div>
        
        <div className="col-md-12">
            <div className="form-group">
                <label className="labelForm" htmlFor="id_usuario">Seleccione provincia:</label>
                <select className="form-select form-select form-control" 
                        aria-label=".form-select-sm example"
                        id="id_provincia"
                        name="id_provincia"
                        value={idProvincia}
                        onChange={idProvinciaOnChangeHandler}>
                  <option >Selecciona provincia:</option>
                  {
                    provincias && provincias.map(provincia => {
                      return <option key={provincia.id} value={provincia.id}>{provincia.nombre}</option> 
                    })
                  }
                </select>
              </div>
              </div><br />

        <button
          className="btn btn-primary btn-lg btn-block boton-azulbio btn-azul"
          type="submit"
          onClick={actualizaEmpresa}
        >
          Guardar
        </button>
        <br />
      </form>
    </div>
  );
};
