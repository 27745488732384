import React, { useEffect, useState } from "react";
import { Global } from "../../../helpers/Global";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import borrar from "../../../img/borrar.svg";
import editar from "../../../img/editar.svg";
import { Link, NavLink } from "react-router-dom";
import { Store } from 'react-notifications-component';
import useUser from "../../../hooks/useUser";



export const ListNaves = ({idGranja}) => {

  const { jwt, isLogged, user, notification, logout } = useUser();

  const [naves, setNaves] = useState([]);
  const [ borrado, setBorrado ] = useState(null);

  const getNavesGranjas= () => {
    fetch(Global.url + "naves/list/" + idGranja, {
      method: "GET",
      headers: { Authorization: "Bearer " + jwt },
    })
    .then((respuesta) => {
      if(respuesta.status === 401){
        Store.addNotification({
          ...notification,
          id: " ",
          title: "Ha habido un error",
          message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
          type: "danger",
        });
        logout()
      }else if(respuesta.status === 500){
        Store.addNotification({
          ...notification,
          id: " ",
          title: "Ha habido un error",
          message: "Ha ocurrido un error",
          type: "danger"
        })
      }

      return respuesta.json()
    })
      .then(
        (resultado_final) => {
          setNaves(resultado_final.data);
        },
        (error) => {
        //  console.log(error);
        }
      );
  };

  useEffect(() => {
    getNavesGranjas();
  }, [borrado]);

  const eliminarNave = async (nave) => {

    const request = await fetch(Global.url + "naves/delete/" + nave, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + jwt
      }
    });
    
    const datos = await request.json();

    if (datos.status === 201 ) {
      setBorrado(nave);
      Store.addNotification({
        ...notification,
        id: " ",
        title: "Nave eliminada",
        message: "Nave eliminada correctamente.",
        type: "success",
      });
    } else {
      setBorrado(nave);
      Store.addNotification({
        ...notification,
        id: " ",
        title: "Ha habido un error",
        message: "No se ha podido borrar la nave. Por favor vueva a intentarlo.",
        type: "danger",
      });
    }
  };

  const borrarNave = (idGranja) => {
    if(window.confirm("Vas a borrar la nave, ¿Quieres continuar?")){
      eliminarNave(idGranja)
    }
  }

  return (
    <div className="container-title">
      
      <h5><span className="spanTitulo">Listado de Naves: </span></h5>

      <nav className="navbar navbar-light bg-light justify-content-around">
      
      <button
                className="btn btn-primary btn-lg btn-block boton-azulbio btn-azul boton-addUser boton-notas"
                type="submit"
              >    
                    
          <NavLink to={"/naves/crear/"+idGranja}>Añadir nueva</NavLink>
        </button>
       
      </nav>
      <div className="listUserNotes">
      {borrado === "true" ? (Store.addNotification({
          ...notification,
          id: " ",
          title: "Nave borrada",
          message: "Nave eliminada correctamente.",
          type: "success",
        })) : (
        ""
      )}
     


        {naves &&
          naves.map((nave, index) => {
            return (
              
              <Card className="notas" sx={{ minWidth: 300 }} key={index}>
                <CardContent>
                  <Link to={"/nave/"+nave.id} >
                    <Typography
                      variant="h6"
                      sx={{ fontSize: 12, fontWeight: 'bold', boxShadow: 1 }}
                      color="text.principal"
                      gutterBottom
                      component='span'
                    >
                      Nombre nave: {nave.nombre} 
                    </Typography>
                  </Link>
                  <Typography sx={{ fontSize: 14 }} component="div">
                   Fecha entrada animales: {nave.fecha_formato}<br/>
                   Estado: {nave.id_estado === '1' ? <span>Vacio sanitario.</span>:''}
                           {nave.id_estado === '2' ? <span>En Curso.</span>:''}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small">
                     <Link to={"/nave/"+nave.id} ><img src={editar} width="20" alt="editar" /></Link>
                  </Button>
                  <Button
                    size="small"
                    onClick={(e) => borrarNave(nave.id)}
                    value={nave.id}
                  >
                    <img src={borrar} width="20" alt="borrar" value={nave.id} />
                  </Button>
                </CardActions>
              </Card>
             
            );
          })}
      </div>
    </div>
  );
};