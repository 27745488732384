import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useUser from "../../../hooks/useUser"
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import FolderIcon from '@mui/icons-material/Folder'

export const ListInformes = ({busqueda, listado}) => {
  const { jwt, notification, logout} = useUser();

  const [categorias, setCategorias] = useState(listado);
  const [borrado, setBorrado] = useState(null);

  const getCategoriasFiltered = () => {
    setCategorias(()=>{
        if(busqueda !== "") {
            return listado.filter(
                categoria => {
                    return (
                      categoria.nombre.toLowerCase().includes(busqueda.toLowerCase()) 
                    );
                }
            )
        }else{
            return listado;
        }
    });
  };


    useEffect(() => {
      getCategoriasFiltered();
    }, [borrado, busqueda, listado]);

  const [open, setOpen] = useState("true");
  
  return (
    <div className="container">

      {categorias &&
        categorias.map((categoria, index) => {
          return (
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} key={categoria.id} >
              <Link to={"/informes/" + categoria.id} >
              <ListItem >
                <ListItemAvatar>
                  <Avatar>
                    <FolderIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={categoria.nombre} />
              </ListItem>
              </Link>
            </List>
          );
        })}
    </div>
  );
};

