import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Global } from "../../../helpers/Global";
import Switch from "@mui/material/Switch";
import { Store } from 'react-notifications-component';
import useUser from "../../../hooks/useUser";
import {Imagen} from "../imagenes/index"
import { LoadingSpinner } from "../../../helpers/LoadingSpinner";

export const FormUser = ({ accion, userId }) => {
  
  const {jwt, isLogged, notification, logout} = useUser();

  const [nombre, setNombre] = useState("");
  const [telefono, setTelefono] = useState("");
  const [email, setEmail] = useState("");
  const [cambiarPassword, setCambiarPassword] = useState(() => {
    if(accion!=="update"){
      return true;
    } else {
      return false;
    }
  });
  const [password, setPassword] = useState("");
  const [password_repeat, setPassword_repeat] = useState("");
   
  const [imagenes, setImagenes] = useState([]);

  const [titleForm, setTitleForm] = useState(accion);

  const [checked, setChecked] = React.useState(() => {
    if(accion!=="update"){
      return true;
    } else {
      return false;
    }
  });

  const [ isLoading, setIsLoading ] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const navigate = useNavigate();

  const nombreOnchangeHandler = (evento) => {
    setNombre(evento.target.value);
  };

  const telefonoOnChangeHandler = (evento) => {
    setTelefono(evento.target.value);
  };

  const emailOnchangeHandler = (evento) => {
    setEmail(evento.target.value);
  };

  const cambiarPasswordOnchangeHandler = (evento) => {
    setCambiarPassword(evento.target.checked);
  };

  const passwordOnchangeHandler = (evento) => {
    setPassword(evento.target.value);
  };

  const passwordRepeatOnChangeHandler = (evento) => {
    setPassword_repeat(evento.target.value);
  };

  const submitUsuario = async (evento) => {
    evento.preventDefault();
    setIsLoading(true);

    const formElement = document.querySelector(".userEdit");
    const data = new FormData(formElement);

    let url = "";

    if (accion === "update") {
      url = Global.url + "usuarios/" + accion + "/" + userId;
    } else {
      url = Global.url + "usuarios/create";
    }
    
    await fetch(url, {
      method: "POST",
      body: data,
      headers: { Authorization: "Bearer " + jwt },
    })
      .then((respuesta) => {
        const data = respuesta.json()
        if(respuesta.status === 401){
          Store.addNotification({
            ...notification,
            id: " ",
            title: "Ha habido un error",
            message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
            type: "danger",
          });
          logout()
        }else if(respuesta.status === 400){
          data.then((value) => {
            Store.addNotification({
              ...notification,
              id: " ",
              title: "Ha habido un error",
              message: value.errors[0].message,
              type: "danger",
            })
          });
          setIsLoading(false);
        }
        else if(respuesta.status === 500){
          Store.addNotification({
            ...notification,
            id: " ",
            title: "Ha habido un error",
            message: "Ha ocurrido un error",
            type: "danger",
          });
          setIsLoading(false)
        }
        return data
      })
      .then(
        (datos) => {

          if (datos.status === 201 ) {
              Store.addNotification({
                ...notification,
                id: " ",
                title:  datos.message,
                message: datos.message,
                type: "success",
              });
              setIsLoading(false);
              navigate("/usuarios");
          }

        }
      ).catch(error => {
       // console.error('There was an error!', error);
    });
    
  };


  const getUserData = () => {
    fetch(Global.url + "usuario/" + userId, {
      method: "GET",
      headers: { Authorization: "Bearer " + jwt },
    })
    .then((respuesta) => {
      if(respuesta.status === 401){
          Store.addNotification({
            ...notification,
            id: " ",
            title: "Ha habido un error",
            message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
            type: "danger",
          });
        logout()
      } else if(respuesta.status === 400){
        Store.addNotification({
          ...notification,
          id: " ",
          title: "Ha habido un error",
          message: "Ha habido un error, Por favor, vuelva a intentarlo.",
          type: "danger",
        });
        navigate('/usuarios')
      } else if(respuesta.status === 500){
        Store.addNotification({
          ...notification,
          id: " ",
          title: "Ha habido un error",
          message: "Ha ocurrido un error",
          type: "danger"
        })
      }

      return respuesta.json()
    })
      .then(
        (resultado_final) => {
          setNombre(resultado_final.data.nombre);
          setTelefono(resultado_final.data.telefono);
          setEmail(resultado_final.data.email);
          setImagenes(resultado_final.data.imagen);
          setChecked(() => {
            if(resultado_final.data.activo === "0"){
              return false;        
            }else{
              return true;
            }
          })
        },
        (error) => {
         // console.log(error);
        }
      );
  };

 

  useEffect(() => {
    if(accion === "update"){
      getUserData();
    } 
  },[accion])

  return (
    <div>
      { isLoading === true ? <LoadingSpinner /> : '' }
      {!isLogged ? <Navigate to="/login" /> : ""}

      <div className={isLoading ? "containerLoading" : "container"}>
        <h5 className="formTitle">
          {titleForm === "update" ? (
            <span className="spanTitulo">Actualizar usuario:</span>
          ) : (
            <span className="spanTitulo">Añadir usuario:</span>
          )}
        </h5>
        <br />
        <div className="contenedor-formulario">
          <form className="row g-3 userEdit" onSubmit={submitUsuario}>
            {titleForm === "update" ? (
              <div className="col-md-12">
                <div classnames="form-group">
                <span className="form-pass spanTitulo">Activo</span>
                <Switch
                  value={checked}
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                  className="form-check-input"
                  id="activo"
                  name="activo"
                />
              </div>
              </div>
            ) : (
              ""
            )}

            <div className="col-md-12">
              <div className="form-group">
                <label className="labelForm" htmlFor="nombre">Nombre: *</label>
              <input
                type="nombre"
                className="form-control"
                id="nombre"
                name="nombre"
                placeholder="Introduzca nombre de usuario"
                value={nombre}
                onChange={nombreOnchangeHandler}
              />
            </div>
            </div>
            <div className="col-md-12">
            <div className="form-group">
                <label className="labelForm" htmlFor="telefono">Teléfono: *</label>
              <input
                type="tel"
                className="form-control"
                id="telefono"
                name="telefono"
                placeholder="Introduzca teléfono de usuario"
                value={telefono}
                onChange={telefonoOnChangeHandler}
              />
            </div>
            </div>

            <div className="col-md-12">
            <div className="form-group">
                <label className="labelForm" htmlFor="email">E-mail: *</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                placeholder="Introduce email de usuario"
                value={email}
                onChange={emailOnchangeHandler}
              />
            </div>
            </div>

            {titleForm === "update" ? (
              <div className="col-md-12">
                <div className="form-group">
                <span className="form-pass spanTitulo">¿Cambiar contraseña?</span>
                <Switch
                  checked={cambiarPassword}
                  onChange={cambiarPasswordOnchangeHandler}
                  inputProps={{ "aria-label": "controlled" }}
                  className="form-check-input"
                  id="cambiar_password"
                  name="cambiar_password"
                />
              </div>
              </div>
            ) : (
              ''
            )}

            
          { cambiarPassword && <>
          <div className="col-md-12">
          <div className="form-group">
                <label className="labelForm" htmlFor="password">Contraseña: *</label>
              <input
                type="password"
                className="form-control"
                id="password"
                name="password"
                placeholder="Introduce tu contraseña"
                value={password}
                onChange={passwordOnchangeHandler}
              />
            </div>
            </div>
            <div className="col-md-12">
            <div className="form-group">
                <label className="labelForm" htmlFor="password_repeat">Repite contraseña: *</label>
              <input
                type="password"
                className="form-control"
                id="password_repeat"
                name="password_repeat"
                placeholder="Repite la contraseña"
                value={password_repeat}
                onChange={passwordRepeatOnChangeHandler}
              />
            </div>
            </div></>}                       
            <Imagen imagenes={imagenes} numImagenes="1"  />

            
                {titleForm === "update" ? (
                 <div className="col-12">
                 <button
                   type="submit"
                   className="btn btn-primary btn-lg btn-block boton-azulbio btn-azul"
                 >Guardar</button></div>
                ) : (
                  <div className="col-12">
                 <button
                   type="submit"
                   className="btn btn-primary btn-lg btn-block boton-azulbio btn-azul"
                 >Crear</button></div>
                )}
              
          </form>
        </div>
      </div>
    </div>
  );
};
