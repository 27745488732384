import React, { useState } from "react";
import {NavLink, Navigate} from "react-router-dom";
import useUser from '../../hooks/useUser';
import loginImage from "../../img/login.svg";
import { Store } from 'react-notifications-component';


export default function LoginNuevo({onLogin}) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const {isLoginLoading, hasLoginError, login, isLogged, cambiarError, notification} = useUser();
  
  const onChangePassword = (e) => {
    cambiarError();
    setPassword(e.target.value);
  }
  const onChangeUsername = (e) => {
    cambiarError();
    setUsername(e.target.value);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    login({ username, password });
  };

  return (
    <>
    {
      isLogged && <Navigate to="/dashboard" />
    }
    
    {
        hasLoginError && Store.addNotification({
          ...notification,
          id: " ",
          title: "Ha habido un error",
          message: "Por favor revise su usuario y contraseña.",
          type: "danger",
        })
      }
    
      {isLoginLoading && <strong>Comprobando credenciales...</strong>}
      {!isLoginLoading &&
      <div className="form-container">
         
        <form className='form row g-3' onSubmit={handleSubmit}>
            <img
              src={loginImage}
              alt="login"
              className="imagen-login"
              height="100px"
            />
          <div className="col-md-12">
            <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            placeholder="E-mail"
            onChange={onChangeUsername}
            value={username}
          />
          </div>

          <div className="col-md-12">
            <input
              type="password"
              className="form-control"
              id="password"
              name="password"
              placeholder="Contraseña"
              onChange={onChangePassword}
              value={password}
            />
          </div>

          <div className="col-md-12">
            <button className='btn btn-primary btn-lg btn-block boton-login btn-azul'>Iniciar sesion</button>
          </div>
          <div className="recoveryPass">
              <NavLink to="/passwordrecovery">
                ¿Has olvidado tu contraseña?
              </NavLink>
            </div>
        </form>
        </div>
      }
      
    </>
  );
}