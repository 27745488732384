import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useUser from "../../../hooks/useUser"
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import FolderIcon from '@mui/icons-material/Folder'
import Desinfectantes from '../../../img/Desinfectantes.svg'
import Higienizantes from '../../../img/Higienizantes.svg'
import Rodenticidas from '../../../img/Rodenticidas.svg'
import Insecticidas from '../../../img/Insecticidas.svg'

export const ListProductos = ({busqueda, listado}) => {
  const { jwt, notification, logout} = useUser();

  const [categorias, setCategorias] = useState(listado);
  const [borrado, setBorrado] = useState("false");

  const getCategoriasFiltered = () => {
    setCategorias(()=>{
        if(busqueda !== "") {
            return listado.filter(
                categoria => {
                    return (
                      categoria.nombre.toLowerCase().includes(busqueda.toLowerCase()) 
                    );
                }
            )
        }else{
            return listado;
        }
    });
  };

    useEffect(() => {
      getCategoriasFiltered();
    }, [borrado, busqueda, listado]);

  const [open, setOpen] = useState("true");
  
  return (
    <div className="container">

      {categorias &&
        categorias.map((categoria, index) => {
          let src;
          if (categoria.id === '1') {
            src =Desinfectantes;
          } else if (categoria.id === '3') {
            src =Rodenticidas;
          } else if (categoria.id === '4') {
            src =Higienizantes;
          } else if (categoria.id === '5') {
            src =Insecticidas;
          }
          return (
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} key={categoria.id}>
              <Link to={"/productos/" + categoria.id} >
              <ListItem  >
                <ListItemAvatar>
                  <img src={src} alt='logo_producto' width={40} ></img>                  
                </ListItemAvatar>
                <ListItemText  primary={categoria.nombre} />
              </ListItem>
              </Link>
            </List>
          );
        })}
    </div>
  );
};

