import React, { useEffect, useState } from "react";
import { Global } from "../../../helpers/Global";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import borrar from "../../../img/borrar.svg";
import editar from "../../../img/editar.svg";
import { Link, NavLink } from "react-router-dom";
import { Store } from 'react-notifications-component';
import useUser from "../../../hooks/useUser";



export const ListNotas = ({tabla, tablaId}) => {

  const { jwt, isLogged, user, notification, logout } = useUser();

  const [notas, setNotas] = useState([]);
  const [ borrado, setBorrado ] = useState(null);

  const userRole = user.role;

  const getUserNotes = () => {
    fetch(Global.url + "notas/all/" + tabla + "/" + tablaId, {
      method: "GET",
      headers: { Authorization: "Bearer " + jwt },
    })
    .then((respuesta) => {
      if(respuesta.status === 401){
        Store.addNotification({
          ...notification,
          id: " ",
          title: "Ha habido un error",
          message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
          type: "danger",
        });
        logout()
      }else if(respuesta.status === 500){
        Store.addNotification({
          ...notification,
          id: " ",
          title: "Ha habido un error",
          message: "Ha ocurrido un error",
          type: "danger"
        })
      }

      return respuesta.json()
    })
      .then(
        (resultado_final) => {
          setNotas(resultado_final.data);
        },
        (error) => {
        //  console.log(error);
        }
      );
  };

  useEffect(() => {
    getUserNotes();
  }, [borrado]);

  const eliminarNota = async (nota) => {

    const request = await fetch(Global.url + "notas/delete/" + nota, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + jwt
      }
    });
    
    const datos = await request.json();

    if (datos.status === 201 ) {
      setBorrado(nota);
    } else {
      setBorrado(nota);
      Store.addNotification({
        ...notification,
        id: " ",
        title: "Ha habido un error",
        message: "No se ha podido borrar la nota. Por favor vueva a intentarlo.",
        type: "danger",
      });
    }
  };

  const borrarNota = (idNota) => {
    if(window.confirm("Vas a borrar la nota, ¿Quieres continuar?")){
      eliminarNota(idNota)
    }
  }

  return (

    <div className="container-title">

      <h5><span className="spanTitulo">Listado de Notas: </span></h5>

      <nav className="navbar navbar-light bg-light ">
     
      <button
                className="btn btn-primary btn-lg btn-block boton-azulbio btn-azul boton-addUser boton-notas"
                type="submit"
              >            
          <NavLink to={"/crear-nota/"+ tabla +"/"+ tablaId}>Añadir nueva</NavLink>
        </button>
        {/*<form className="form-inline">
          <input
            className="form-control mr-sm-2 buscador"
            type="search"
            placeholder="Search"
            aria-label="Search"
            />
  </form>*/}
        
      </nav>
      <div >
      {borrado === "true" ? (Store.addNotification({
          ...notification,
          id: " ",
          title: "Nota borrada",
          message: "Nota eliminada correctamente.",
          type: "success",
        })) : (
        ""
      )}


        {notas &&
          notas.map((nota, index) => {
            return (
              
              <Card className="notas" sx={{ minWidth: 300 }} key={index}>
                <CardContent>
                <Link to={"/nota/"+nota.id} >
                    <Typography
                      variant="h6"
                      sx={{ fontSize: 12, fontWeight: 'bold', boxShadow: 1 }}
                      color="text.principal"
                      gutterBottom
                      component='span'
                    >
                      {nota.nombre_usuario} | {nota.fecha_formato}
                    </Typography>
                  </Link>
                  <Typography sx={{ fontSize: 14 }} component="div">
                    {nota.nota}
                  </Typography>
                </CardContent>
                <CardActions>
                  {(userRole === '1' || user.id===nota.id_usuario ) ? (<><Button size="small">
                     <Link to={"/nota/"+nota.id} ><img src={editar} width="20" alt="editar" /></Link>
                  </Button>
                  <Button
                    size="small"
                    onClick={(e) => borrarNota(nota.id)}
                    value={nota.id}
                  >
                    <img src={borrar} width="20" alt="borrar" value={nota.id} />
                  </Button> </>) : ('')}
                </CardActions>
              </Card>
           
            );
          })}
      </div>
    </div>
  );
};