import React, { useState } from "react";
import { Global } from "../../../helpers/Global";

export const PasswordRecovery = () => {

  const [email, setEmail] = useState("");

  const emailOnchangeHandler = (evento) => {
    setEmail(evento.target.value);
  };

  const passwordRecovery = async (evento) => {
    evento.preventDefault();

    const data = new FormData();
    data.append("email", email);


    const request = await fetch(Global.url + "usuarios/recovery", {
      method: "POST",
      body: data,
    });

    const datos = await request.json();

  };
  
  return (
    <div>
      <div className="container">
        <h1>Recuperar contraseña: </h1>
        <br />
        <div className="contenedor-formulario">
          <form className="row g-3" onSubmit={passwordRecovery}>
            <div className="col-md-12">
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                placeholder="Email usuario"
                value={email}
                onChange={emailOnchangeHandler}
              />
            </div>
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-primary btn-lg btn-block boton-azulbio btn-azul"
              >
                Solicitar contraseña &gt;
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
