import React, { useEffect, useState } from "react";
import { Global } from "../../../helpers/Global";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import borrar from "../../../img/borrar.svg";
import editar from "../../../img/editar.svg";
import { Link } from "react-router-dom";
import { Store } from 'react-notifications-component';
import useUser from "../../../hooks/useUser"

export const ListForms = ({busqueda, listado}) => {
  const { jwt, notification, logout} = useUser();

  const [formularios, setFormularios ] = useState('');

  const [borrado, setBorrado] = useState('');

  const getFormsFiltered = () => {
    setFormularios(()=>{
        if(busqueda !== "") {
            return listado.filter(
                formulario => {
                    return (
                        formulario.nombre_formulario.toLowerCase().includes(busqueda.toLowerCase()) ||
                        formulario.granja?.toLowerCase().includes(busqueda.toLowerCase()) ||
                        formulario.fecha_formato?.toLowerCase().includes(busqueda.toLowerCase())
                    );
                }
            )
        }else{
            return listado;
        }
    });
  };

  const eliminarFormulario = async (formulario, posicion) => {
      if (window.confirm("Vas a borrar al formulario. ¿Quieres continuar?")){
            const request = await fetch(Global.url + "formulario/delete/" + formulario, {
              method: "DELETE",
              headers: { Authorization: "Bearer " + jwt },
            });

            const datos = await request.json();

            if (datos.status === 201) {
                listado.splice(posicion, 1);
                Store.addNotification({
                  ...notification,
                  id: " ",
                  title: "Usuario borrado",
                  message: "Usuario borrado correctamente.",
                  type: "success",
                })
                setBorrado(formulario)
            } else if(datos.status === 401){
              Store.addNotification({
                ...notification,
                id: " ",
                title: "Ha habido un error",
                message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
                type: "danger",
              });
              logout()
            }
            else if(datos.status === 400){
              datos.then((value) => {
                Store.addNotification({
                  ...notification,
                  id: " ",
                  title: "Ha habido un error",
                  message: value.errors[0].message,
                  type: "danger",
                })
              });
            }
            else if(datos.status === 500){
              Store.addNotification({
                ...notification,
                id: " ",
                title: "Ha habido un error",
                message: "Ha ocurrido un error",
                type: "danger",
              })
            }
      }
  };

    useEffect(() => {
        getFormsFiltered();
    }, [busqueda, listado, borrado]);

  return (
    <div className="contenedorListadoVeterinario">
      
      {formularios &&
        formularios.map((formulario, index) => {
          return (
            <Card
              className="listaVeterinarios"
              sx={{ minWidth: 300 }}
              key={index}
            >
              <CardContent>
              <Link to={"/formulario/"+formulario.id} ><Typography variant="h6" color="text.principal" gutterBottom>
                  Formulario: {formulario.nombre_formulario}
                </Typography> </Link>
                <Typography sx={{ fontSize: 14 }} color="text.secondary"
                  gutterBottom
                  component='span' variant='body2'>
                  Tipo: {formulario.id_tipo_formulario === '1' ? 'Bioseguridad Externa' : 'Bioseguridad Interna'}
                  <br />
                  Fecha: {formulario.fecha_formato}
                </Typography><br/>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                  component='span' variant='body2'
                >
                 { formulario.granja !== 'undefined' ? (<><span>Granja:{formulario.granja}</span><br /> </>) : ('')}
                 {formulario.id_tipo_formulario === '2' ? <span> Nave: {formulario.nave}</span> : ''}
                 {formulario.puntuacion && <span> Puntuación: {formulario.puntuacion} {formulario.puntuacion_total ? '/ '+formulario.puntuacion_total : ''}</span> }
                </Typography>
              </CardContent>
              { formulario.id_estado !== '2' ? (<CardActions>
                <Button size="small">
                  <Link to={"/formulario/"+formulario.id} ><img src={editar} width="20" alt="editar" /></Link>
                </Button>
                <Button
                  size="small"
                  onClick={(e) => eliminarFormulario(formulario.id, index)}
                  value={formulario.id}
                >
                  <img
                    src={borrar}
                    width="20"
                    alt="borrar"
                    value={formulario.id}
                  />
                </Button>
              </CardActions>) : (<Button size="large">
                  <Link to={"/formulario/"+formulario.id} >Ver resultados</Link>
                </Button>)}
               
            </Card>
          );
        })}
    </div>
  );
};

