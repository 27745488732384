import './App.css';
import { Routing } from './router/Routing';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import './fonts/Sansation/Sansation_Regular.ttf'

function App() {
  return (
   
    <div className="App">
        <ReactNotifications />
        <Routing />
    </div>
   
  )
}

export default App