import React from 'react'
import { ColorRing } from  'react-loader-spinner'

export const LoadingSpinner = () => {
  return (
    <div className='spinnerContainer'>
       <ColorRing
  visible={true}
  height="160"
  width="160"
  ariaLabel="blocks-loading"
  wrapperStyle={{}}
  wrapperClass="blocks-wrapper"
  colors={['#1DBBFF', '#1DBBFF', '#204B83', '#204B83', '#1DBBFF']}
/>
  </div>
  )
}
