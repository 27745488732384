import React, { useEffect, useState } from "react";
import { Global } from "../../../helpers/Global";
import { Navigate } from "react-router-dom";
import { Store } from 'react-notifications-component';
import useUser from "../../../hooks/useUser"
import IconBorrar from "../../../img/borrar.svg";
import IconMas from "../../../img/mas.svg";
import FsLightbox from "fslightbox-react";
import './index.css'

export const Imagen = ({ imagenes, numImagenes, accion = '' }) => {

    const { jwt, logout, notification } = useUser();
    const [imagen, setImagen] = useState([]);
    const [imagenesIDs, setimagenesIDs] = useState([]);
    const [openPopup, setOpenPopup] = useState(false);
    const [urlPopup, setUrlPopup] = useState([]);

    const clickPopupHandler = (src) => {
        setUrlPopup([src])
        setTimeout(() => {
            setOpenPopup(prev => !prev)
          }, 100);
    };

    const eliminarImagen = async(imagen,posicion) => {
        if(window.confirm("Vas a borrar la imagen, ¿Quieres continuar?")){
            const request = await fetch(Global.url + "imagenes/delete/" + imagen, {
                method: "DELETE",
                headers: { Authorization: "Bearer " + jwt },
            });
            const datos = await request.json();
            if (datos.status === 201) {
                setimagenesIDs(imagenes.splice(posicion,1));
            }
        }
    };

    const getImagenes = async () => {
        if (Array.isArray(imagenes)) {

            const ImagenesAPI = await Promise.all(
                imagenes.map(ImageItem =>
                    fetch(Global.url + "imagen/view/" + ImageItem.id, {
                        method: "GET",
                        headers: {Authorization: "Bearer " + jwt},
                    })
                        .then((respuesta) =>
                        {
                            if(respuesta.status === 401){
                                Store.addNotification({
                                    ...notification,
                                    id: " ",
                                    title: "Ha habido un error",
                                    message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
                                    type: "danger",
                                  });
                                logout()
                            }
                            else if(respuesta.status === 500){
                                Store.addNotification({
                                    ...notification,
                                    title: "Ha habido un error",
                                    message: "Ha ocurrido un error",
                                    type: "danger",
                                })
                            }

                            return respuesta.blob()
                        }
                        )
                        .then(blob => {
                                let objectURL = URL.createObjectURL(blob);
                                return objectURL;
                            },
                            (error) => {
                               // console.log(error);
                            }
                        )
                )
            )
            return ImagenesAPI
        }
    };

    useEffect(() => {
        const loadImgList = async () => {
            try {
                const ImgList = await getImagenes();
                setImagen(ImgList)
            } catch(error) {
            }
        };
        loadImgList()
        setimagenesIDs(imagenes)

    },[imagenes, imagenesIDs])

    
    let images = [];
    numImagenes === '1'  ? images = imagenesIDs.slice(0,1) : images = imagenesIDs;

    return (
        <div>
            {!jwt ? <Navigate to="/login" /> : ""}

            { accion !== 'producto' && accion !=='listadoProducto' && (numImagenes > '1' || imagenesIDs.length===0) ? <div className="col-md-12">
            <div className="form-group">
                <label className="labelForm" htmlFor="customFile">
                    Selecciona tu imagen:
                </label>
                <input
                    type="file"
                    className="form-control"
                    id="customFile"
                    name="formFile" />
            </div></div> : ""}

            <div className="imagenList">

               { images &&  images.map((item,i) => 
                   <div key={item.id} className="imagenItem">
                       { accion !== 'producto' && accion !=='listadoProducto' ? (<img src={IconBorrar} className="iconBorrar" width="20" alt="Borrar imagen" onClick={(e) => eliminarImagen(item.id,i)}/>) : ('')}
                      { accion !=='listadoProducto' ? (<img src={IconMas} className="iconAmpliar" width="20" alt="Ampliar imagen" onClick={() => clickPopupHandler(imagen[i])}/>) : ('')}
                       <img src={imagen[i]} className={accion ==='producto' ? ('producto'):('imageItem') || accion ==='listadoProducto' ? ('listadoProducto'):('imageItem') } alt=""/>
                   </div>
               )
            }
            </div>

            <FsLightbox
                toggler={openPopup}
                sources={urlPopup}
                key={urlPopup}
                type="image"
            />

        </div>
    );
};