import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Global } from "../../../helpers/Global";
import { Store } from 'react-notifications-component';
import useUser from "../../../hooks/useUser"
import { Imagen } from "../imagenes";
import { LoadingSpinner } from "../../../helpers/LoadingSpinner";

export const FormNotes = ({ tabla, accion, notaId }) => {

  const { jwt, isLogged, user, logout, notification} = useUser();
  const datosUrl = useParams();
  const tabName = datosUrl.tabla;
  const tabId = datosUrl.id;
  const userRole = user.role;

  const [usuario, setUsuario] = useState({});

  const [imagenes, setImagenes] = useState([]);
  const [ imagenNota, setImagenNota ] = useState('');
  const [ imagenNotaId, setImagenNotaId ] = useState('');
  
  const [nota, setNota] = useState("");
 
  const [saved, setSaved] = useState("not_saved");
  const [open, setOpen] = useState("true");

  const [action, setAction] = useState(accion);

  const [titleForm, setTitleForm] = useState(accion);

  const [checked, setChecked] = React.useState(true);

  const [ isLoading, setIsLoading ] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const navigate = useNavigate();

  const notaOnchangeHandler = (evento) => {
    setNota(evento.target.value);
  };


  const submitNotas = async (evento) => {
    evento.preventDefault();
    setIsLoading(true);

    const formElement = document.querySelector(".notasEdit");
    const data = new FormData(formElement);

    let url = "";
    
    if (accion === "update") {
      url = Global.url + "notas/" + accion + "/" + notaId;
    } else {
      url = Global.url + "notas/create/" + tabName + "/" + tabId;
    }
    
    const request = await fetch(url, {
      method: "POST",
      body: data,
      headers: { Authorization: "Bearer " + jwt },
    });

    const datos = await request.json();

    if (datos.status === 401 ) {
      Store.addNotification({
        ...notification,
        id: " ",
        title: "Ha habido un error",
        message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
        type: "danger",
      });
      logout();
    }else if(datos.status === 500){
      Store.addNotification({
        ...notification,
        id: " ",
        title: "Ha habido un error",
        message: "Ha ocurrido un error",
        type: "danger"
      });
      setIsLoading(false);
    }

    setSaved(datos.message);

    if (datos.message === "Nota creada correctamente") {
      Store.addNotification({
        ...notification,
        id: " ",
        title: "Nota creada",
        message: "Nueva nota añadida correctamente.",
        type: "success",
      });
        setIsLoading(false);
        navigate(-1);
        setSaved(datos.message);
        datos.message = null;
    } 

    if (datos.message === "Nota modificada correctamente") {
      Store.addNotification({
        ...notification,
        id: " ",
        title: "Nota modifictada",
        message: "Nota modificada correctamente.",
        type: "success",
      });
        setIsLoading(false);
        navigate(-1);    
        setSaved(datos.message);
        datos.message = null;
    } 
    
  };

    const getNotasData = () => {
      fetch(Global.url + "nota/" + notaId, {
        method: "GET",
        headers: { Authorization: "Bearer " + jwt },
      })
      .then((respuesta) => {
        if(respuesta.status === 401){
          Store.addNotification({
            ...notification,
            id: " ",
            title: "Ha habido un error",
            message: "Error de autenticación. Por favor. vuelva a iniciar sesión.",
            type: "danger",
          });
          logout()
        } else if(respuesta.status === 404){
          Store.addNotification({
            ...notification,
            id: " ",
            title: "Ha habido un error",
            message: "Ha habido un error. Por favor, vuelva a intentarlo.",
            type: "danger",
          });
          navigate('/dashboard')
        }else if(respuesta.status === 500){
          Store.addNotification({
            ...notification,
            title: "Ha habido un error",
            message: "Ha ocurrido un error",
            type: "danger"
          })
        }

        return respuesta.json()
      })
        .then(
          (resultado_final) => {
            setNota(resultado_final.data.nota);
            setImagenNotaId(resultado_final.data.imagen[0].id);
            setImagenes(resultado_final.data.imagen);
          },
          (error) => {
            //console.log(error);
          }
        );
    };

  useEffect(() => {
    if ( accion === "update"){
      getNotasData();
    }
  },[])


  return (
    <div>
      { isLoading === true ? <LoadingSpinner /> : '' }
      {!isLogged ? <Navigate to="/login" /> : ""}

      <div className={isLoading ? "containerLoading" : "container"}>
        <h5 className="formTitle">
          {titleForm === "update" ? (
            <span className="spanTitulo" >Actualizar nota:</span>
          ) : (
            <span className="spanTitulo">Añadir nota:</span>
          )}
        </h5>
        <br />
        
        <div className="contenedor-formulario">
          <form className="row g-3 notasEdit" onSubmit={submitNotas}>
            <div className="col-md-12">
            <div className="form-group">
                  <label className="labelForm" htmlFor="nota">Nota: </label>
              <textarea
                type="text"
                className="form-control"
                id="nota"
                name="nota"
                rows='10'
                placeholder="Introduzca nota"
                value={nota}
                onChange={notaOnchangeHandler}
              />
            </div>
            </div>
           
               <Imagen imagenes={imagenes} numImagenes="1"  />
            
                {titleForm === "update" ? (
                 <div className="col-12">
                 <button
                   type="submit"
                   className="btn btn-primary btn-lg btn-block boton-azulbio btn-azul"
                 >Guardar</button></div>
                ) : (<div className="col-12">
                <button
                  type="submit"
                  className="btn btn-primary btn-lg btn-block boton-azulbio btn-azul"
                >Crear</button></div>)}

             
          </form>
        </div>
      </div>
    </div>
  );
};
