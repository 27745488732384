import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { FormUser } from "./Form";
import { ListNotas } from "../notas/List"

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ span: 3 }}>
          <Typography component='span' variant='body2'>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const EditUser = () => {
  const userId = useParams();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }} >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            centered
          >
            <Tab label="Datos" {...a11yProps(0)} />
            <Tab label="Notas" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}  component='span' variant='body2' >
          <FormUser accion="update" userId={userId.id} />
        </TabPanel>
        <TabPanel value={value} index={1} component='span' variant='body2'>
          <ListNotas tabla="usuarios" tablaId={userId.id} />
        </TabPanel>
      </Box>
    </div>
  );
};
