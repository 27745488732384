const ENDPOINT = 'https://backend.biosecuritytrack.com/api/v1/usuarios'

export default function login ({ username, password }) {

  const formData = new FormData();
  formData.append("email", username);
  formData.append("password", password);

  return fetch(`${ENDPOINT}/login`, {
    method: 'POST',
    body: formData,
  }).then(res => {
    if (!res.ok) throw new Error(res.json())
    return res.json()
  }).then(res => {
    const token  = res.data.token;
    const user = res.data;
    return { token,
              user}
  })
}