import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useUser from "../../../hooks/useUser";
import listProducto from "../../../services/producto";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { common } from "@mui/material/colors";
import { Store } from "react-notifications-component";
import {Imagen} from "../imagenes/index"


export const FichaProducto = () => {
  const datosURL = useParams();
  const idProducto = datosURL.id;

  const { jwt, notification, logout } = useUser();

  const [producto, setProducto] = useState("");

  const [imagenes, setImagenes] = useState([]);

  const navigate = useNavigate();

  const getProducto = () => {
    listProducto(jwt, idProducto).then((data) => {
      if (data === 401) {
        Store.addNotification({
          ...notification,
          id: " ",
          title: "Ha habido un error",
          message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
          type: "danger",
        });
        logout();
      } else if(data === 400){
        Store.addNotification({
          ...notification,
          id: " ",
          title: "Ha habido un error",
          message: "Ha habido un error. Por favor, vuelva a intentarlo.",
          type: "danger",
        });
        navigate('/categorias-productos')
      }else {
        setProducto(data);
        setImagenes(data.imagen);
      }
    });
  };

  useEffect(() => {
    getProducto();
  }, []);

  const verPdfProducto = (numPdf, idProducto, nombreProducto) => {
    const ENDPOINT = "https://backend.biosecuritytrack.com/api/v1";

    return fetch(`${ENDPOINT}/producto/view-pdf/` + numPdf + "/" + idProducto, {
      method: "GET",
      headers: { Authorization: "Bearer " + jwt },
    })
      .then((res) => {
        const data = res.blob();
        if (res.status === 401) {
          Store.addNotification({
            ...notification,
            id: " ",
            title: "Ha habido un error",
            message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
            type: "danger",
          });
          logout();
        }
        return data;
      })
      .then((res) => {
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(res);
        link.download = nombreProducto;
        link.click();
      });


  };

  return (
    <div className="container container-fichaProducto">
      <div className="col-md-12">
        <span className="spanProducto"><span className="tituloProducto">Nombre:</span> {producto.nombre}</span>
      </div> 
      <Imagen imagenes={imagenes} numImagenes="2" accion="producto" />

      {producto.descripcion && (
        <div className="col-md-12">
          <span className="spanProducto"><span className="tituloProducto">Descripción:</span> {producto.descripcion}</span>
        </div>
      )}
      <div className="col-md-12">
        <br />
        <span>Pulsa para descargar los pdfs</span>
        <br/>
        <br/>
        <div className="form-group form-actions">
            {producto.pdf_1 && (
              <>
                <button className="btn btn-primary btn-lg btn-block boton-azulbio btn-azul btn-producto"
                  onClick={(e) =>
                    verPdfProducto(1, producto.id, producto.pdf_1)
                  }
                >
                  <span className="botonPdf">{producto.pdf_1} </span>
                  <PictureAsPdfIcon
                    sx={{ fontSize: 15, color: common.white }}
                  />
                </button>
              </>
            )}
            </div>
            <div className="form-group form-actions">
         
            {producto.pdf_2 && (
              <>
                <button className="btn btn-primary btn-lg btn-block boton-azulbio btn-azul btn-producto"
                  onClick={(e) =>
                    verPdfProducto(2, producto.id, producto.pdf_2)
                  }
                ><span className="botonPdf">{producto.pdf_2} </span>
                  <PictureAsPdfIcon
                    sx={{ fontSize: 15, color: common.white }}
                  />
                </button>
              </>
            )}
        </div>
      </div>
    </div>
  );
};
