import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Dashboard } from "../components/pages/Dashboard";
import { Login } from "../components/pages/Login.js";
import { Usuarios } from "../components/pages/Usuarios";
import { PasswordRecovery } from "../components/modulos/usuarios/PasswordRecovery";
import { MiPerfil } from "../components/pages/MiPerfil";
import { Cabecera } from "../components/layout/Cabecera";
import { Footer } from "../components/layout/Footer";
import { UserContextProvider } from "../context/UserContext";
import { EditUser } from "../components/modulos/usuarios/Edit";
import { FormUser } from "../components/modulos/usuarios/Form";
import { FormNotes } from "../components/modulos/notas/Form"
import { EditNotas } from "../components/modulos/notas/Edit";
import ContactForm from "../components/pages/Contacto";
import { MiEmpresa } from "../components/pages/MiEmpresa";
import { Granjas } from "../components/pages/Granjas";
import { FormGranjas } from "../components/modulos/granjas/Form";
import { EditGranjas } from "../components/modulos/granjas/Edit";
import { EditNaves } from "../components/modulos/naves/Edit";
import { FormNaves } from "../components/modulos/naves/Form";
import { Productos } from "../components/pages/Productos";
import { EditProductos } from "../components/modulos/productos/Edit";
import { FichaProducto } from "../components/modulos/productos/Ficha";
import { Tareas } from "../components/pages/Tareas";
import { FormTareas } from "../components/modulos/tareas/Form";
import { EditTareas } from "../components/modulos/tareas/Edit";
import { Formularios } from "../components/pages/Formularios";
import { FormForms } from "../components/modulos/formularios/Form";
import { EditForms } from "../components/modulos/formularios/Edit";
import { Informes } from "../components/pages/Informes";
import { FichaInformes } from "../components/modulos/informes/Ficha";
import { EditInformes } from "../components/modulos/informes/Edit";
import { Evolucion } from "../components/pages/Evolucion";
import { Inicio } from "../components/pages/Inicio";
import { FormInformes } from "../components/modulos/informes/Form";
import { SplashProvider } from "../context/SplashContext";



export const Routing = () => {
  return (
   
    <BrowserRouter>
    
    <UserContextProvider>
      <Cabecera />
      <SplashProvider>
      <div className="contenido">
        
        
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          
          <Route path="/usuarios" element={<Usuarios />} />
          <Route path="/usuario/:id" element={<EditUser />} />
          <Route path="/usuarios/crear" element={<FormUser />} />
          <Route path="/passwordRecovery" element={<PasswordRecovery />} />

          <Route path="/categorias-productos" element={<Productos />} />
          <Route path="/productos/:id" element={<EditProductos />} />
          <Route path="/producto/:id" element={<FichaProducto />} />

          <Route path="/categorias-informes" element={<Informes />} />
          <Route path="/informes/:id" element={<EditInformes />} />
          <Route path="/informe/:id" element={<FichaInformes />} />
          <Route path="/informes/crear" element={<FormInformes />} />
          

          <Route path="/granjas" element={<Granjas />} />
          <Route path="/granja/:id" element={<EditGranjas accion="update"/>} />
          <Route path="/granjas/crear/" element={<FormGranjas />} />

          <Route path="/nave/:id" element={<EditNaves />} />
          <Route path="/naves/crear/:id" element={<FormNaves />} />

          <Route path="/tareas" element={<Tareas />} />
          <Route path="/tarea/:id" element={<EditTareas accion="update" />} />
          <Route path="/tareas/crear" element={<FormTareas />} />

          <Route path="/formularios" element={<Formularios />} />
          <Route path="/formulario/:id" element={<EditForms />} />
          <Route path="/formularios/crear" element={<FormForms />} />
          

          <Route path="/crear-nota/:tabla/:id" element={<FormNotes />} />
          <Route path="/nota/:id" element={<EditNotas />} />

          <Route path="/empresa" element={<MiEmpresa /> } />

          <Route path="/evolucion" element={<Evolucion /> } />
          
          <Route path="/cabecera" element={<Cabecera />} />

          <Route path="/contacto" element={<ContactForm /> } />
          
          <Route path="/miperfil" element={<MiPerfil />} />
          <Route path="*" element={<Dashboard />} />
        </Routes>
        
      </div>
      <Footer />
      </SplashProvider>
     </UserContextProvider>
    
    </BrowserRouter>
  );
};
