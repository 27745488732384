import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Global } from "../../../helpers/Global";
import { Store } from 'react-notifications-component';
import useUser from "../../../hooks/useUser"
import Switch from "@mui/material/Switch";
import { LoadingSpinner } from "../../../helpers/LoadingSpinner";


export const FormNaves = ({ accion, idNave }) => {

  const { jwt, isLogged, user, logout, notification} = useUser();
  const datosUrl = useParams();
  const idGranja = datosUrl.id;

  const userRole = user.role;

  const [usuario, setUsuario] = useState({});

  const [imagenes, setImagenes] = useState([]);
  
  const [nave, setNave] = useState({});
 
  const [saved, setSaved] = useState("not_saved");
  const [open, setOpen] = useState("true");

  const [ imagenNave, setImagenNave ] = useState('');
  const [ imagenNaveId, setImagenNaveId ] = useState('');


  const [action, setAction] = useState(accion);

  const [titleForm, setTitleForm] = useState(accion);

  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const navigate = useNavigate();

   const [ granjaId, setGranjaID] = useState(idGranja)

   const [ nombreNave, setNombreNave ] = useState('');
   const [ activoNave, setActivoNave ] = useState('');
   const [ idEstadoNave, setIdEstadoNave ] = useState('');
   const [ fechaEntradaNave, setFechaEntradaNave ] = useState('');

   const [ isLoading, setIsLoading ] = useState(false);

   const nombreNaveOnchangeHandler = (evento) => {
    setNombreNave(evento.target.value);
   };

   const activoNaveOnchangeHandler = (evento) => {
    setActivoNave(evento.target.value);
   };
   
   const idEstadoNaveOnchangeHandler = (evento) => {
    setIdEstadoNave(evento.target.value);
   };
   
   const fechaEntradaNaveOnchangeHandler = (evento) => {
    setFechaEntradaNave(evento.target.value);
   };

  const submitNaves = async (evento) => {
    evento.preventDefault();
    setIsLoading(true);

    const formElement = document.querySelector(".navesEdit");
    const data = new FormData(formElement);

    let url = "";
    
    if (accion === "update") {
      url = Global.url + "naves/" + accion + "/" + idNave;
    } else {
      url = Global.url + "naves/create/" + idGranja;
    }
    
    const request = await fetch(url, {
      method: "POST",
      body: data,
      headers: { Authorization: "Bearer " + jwt },
    });

    const datos = await request.json();

    if (datos.status === 401 ) {
      Store.addNotification({
        ...notification,
        id: " ",
        title: "Ha habido un error",
        message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
        type: "danger",
      });
      logout();
    }else if(datos.status === 500){
      Store.addNotification({
        ...notification,
        id: " ",
        title: "Ha habido un error",
        message: "Ha ocurrido un error",
        type: "danger"
      });
      setIsLoading(false);
    }

    setSaved(datos.message);
    
    if (datos.message === "Nave creada correctamente") {
      Store.addNotification({
        ...notification,
        id: " ",
        title: "Nave creada",
        message: "Nueva nave   añadida correctamente.",
        type: "success",
      });
        setIsLoading(false);
        navigate(-1);
        setSaved(datos.message);
        datos.message = null;
    } 

    if (datos.message === "Nave modificada correctamente") {
      Store.addNotification({
        ...notification,
        id: " ",
        title: "Nave modifictada",
        message: "Nave modificada correctamente.",
        type: "success",
      });
        setIsLoading(false);
        navigate(-1);
        setSaved(datos.message);
        datos.message = null;
    } 
    
  };

    const getNavesData = () => {
      fetch(Global.url + "naves/" + idNave, {
        method: "GET",
        headers: { Authorization: "Bearer " + jwt },
      })
      .then((respuesta) => {
        if(respuesta.status === 401){
          Store.addNotification({
            ...notification,
            id: " ",
            title: "Ha habido un error",
            message: "Error de autenticación. Por favor vuelva a iniciar sesión.",
            type: "danger",
          });
          logout()
        } else if(respuesta.status === 400){
          Store.addNotification({
            ...notification,
            id: " ",
            title: "Ha habido un error",
            message: "Ha habido un error. Por favor, vuelva a intentarlo.",
            type: "danger",
          });
          navigate('/dashboard')
        }else if(respuesta.status === 500){
          Store.addNotification({
            ...notification,
            title: "Ha habido un error",
            message: "Ha ocurrido un error",
            type: "danger"
          })
        }

        return respuesta.json()
      })
        .then(
          (resultado_final) => {
            setNombreNave(resultado_final.data.nombre);
            setIdEstadoNave(resultado_final.data.id_estado);
            setFechaEntradaNave(resultado_final.data.fecha_formato);
            setNave(resultado_final.data);
            setChecked(() => {
              if(resultado_final.data.activo === "0"){
                return false;        
              }else{
                return true;
              }
            })
          },
          (error) => {
            //console.log(error);
          }
        );
    };

  useEffect(() => {
     if ( accion === "update"){
      getNavesData();
    }
  },[])


  return (
    <div>
      { isLoading === true ? <LoadingSpinner /> : '' }
      {!isLogged ? <Navigate to="/login" /> : ""}

      <div className={isLoading ? "containerLoading" : "container"}>
        <h5 className="">
          {titleForm === "update" ? (
            <span className="spanTitulo">Actualizar nave:</span>
          ) : (
            <span className="spanTitulo">Añadir nave:</span>
          )}
        </h5>
        <br />

        <div className="contenedor-formulario">
          <form className="row g-3 navesEdit" onSubmit={submitNaves}>

          {titleForm === "update" ? (
              <div className="col-md-12">
                <div className="form-group">
                <span className="form-pass spanTitulo">Activo</span>
                <Switch
                value={checked}
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                  className="form-check-input"
                  id="activo"
                  name="activo"
                />
              </div></div>
            ) : (
              ""
            )}

            <div className="col-md-12">
            <div className="form-group">
                <label className="labelForm" htmlFor="nombre">Nombre: *</label>
              <textarea
                type="text"
                className="form-control"
                id="nombre"
                name="nombre"
                placeholder="Introduzca el nombre de la nave"
                value={nombreNave}
                onChange={nombreNaveOnchangeHandler}
              />
            </div></div>

            <div className="col-md-12">
            <div className="form-group">
                <label className="labelForm" htmlFor="id_estado">Seleccione estado nave:</label>
            <select className="form-select form-select form-control" 
                        aria-label=".form-select-sm example"
                        id="id_estado"
                        name="id_estado"
                        value={idEstadoNave}
                        onChange={idEstadoNaveOnchangeHandler}>
                  <option >Estado de la nave: </option>
                    <option value="1">Vacio sanitario</option> 
                    <option value="2">En curso</option> 
                </select>
            </div></div>

            <div className="col-md-12">
            <div className="form-group">
                <label className="labelForm" htmlFor="f_entrada_animales">Fecha de entrada de animales:</label>
              <input
                type="date"
                className="form-control"
                id="f_entrada_animales"
                name="f_entrada_animales"
                value={fechaEntradaNave}
                onChange={fechaEntradaNaveOnchangeHandler}
              />
            </div></div>

            
                { titleForm === "update" ? (
                 <div className="col-12">
                 <button
                   type="submit"
                   className="btn btn-primary btn-lg btn-block boton-azulbio btn-azul"
                 >Guardar</button></div>
                ) : ( <div className="col-12">
                <button
                  type="submit"
                  className="btn btn-primary btn-lg btn-block boton-azulbio btn-azul "
                >Crear</button></div>)}

              
          </form>
        </div>
      </div>
    </div>
  );
};
